import { immerable } from 'immer'
import { generate } from 'shortid'
import { DiagramLanguage, IDiagram, ILayoutProperties, INote } from '../DiagramApiDTO'
import { translate } from '../utils'
import Corporation from './Corporation'
import { IAppDiagram } from './IGraphState'
import Person from './Person'
import { personFactory } from './personAttributer'

const translations = {
  diagram1: {
    en: 'Org Chart 1',
    fr: 'Organigramme 1',
  },
}

export class Diagram implements IAppDiagram {
	id: string = generate()
	name: string = `${translate(translations.diagram1, this.getLanguage())}`
	persons: Person[] = [ new Corporation({ name: 'Corporation 1' }) ]
	title: { value: string; size: number } = { value: '', size: 14 }
	horizontalSpacing: number = 10
	verticalSpacing: number = 10
	fontFamily: string = 'Helvetica'
	fontColor: string = '#000000'
	fontSize: number = 12
	showTitle?: boolean = false
	showLegend: boolean = false
	confidentialMode: boolean = false
	footNotes: INote[] = []
	sidePadding: number = 0.5 * this.fontSize
	topPadding: number = 0.5 * this.fontSize
	shapesBorderWidth: number = 1
	language: DiagramLanguage = DiagramLanguage.EN;
	[immerable] = true

  constructor(args: Partial<IDiagram> = {}) {
    this.id = args.id || this.id
    this.name = args.name || this.name
    this.persons =
      args.persons && args.persons.length ? args.persons.map((person) => personFactory(person)) : this.persons
    this.horizontalSpacing = args.horizontalSpacing || this.horizontalSpacing
    this.verticalSpacing = args.verticalSpacing || this.verticalSpacing
    this.fontFamily = args.fontFamily || this.fontFamily
    this.showLegend = args.showLegend || this.showLegend
    this.showTitle = args.showTitle || this.showTitle
    this.confidentialMode = args.confidentialMode || this.confidentialMode
    this.title = args.title || this.title
    this.fontColor = args.fontColor || this.fontColor
    this.footNotes = args.footNotes || this.footNotes
    this.fontSize = args.fontSize || this.fontSize
    this.sidePadding = args.sidePadding != undefined ? args.sidePadding : this.sidePadding
    this.topPadding = args.topPadding != undefined ? args.topPadding : this.topPadding
    this.language = args.language || this.language
  }

  getLayoutProperties(): ILayoutProperties {
    return {
      topPadding: this.topPadding,
      sidePadding: this.sidePadding,
      fontSize: this.fontSize,
      smallFont: Math.round(this.fontSize * 0.7),
      textSpacing: Math.round(this.fontSize * 0.2),
      fontFamily: this.fontFamily,
      shapesBorderWidth: this.shapesBorderWidth,
    }
  }

  getLanguage(): 'fr' | 'en' {
    if (this.language === DiagramLanguage.EN) {
      return 'en'
    } else {
      return 'fr'
    }
  }
}

export default Diagram
