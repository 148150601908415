import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'raf/polyfill'
import React from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { render } from 'react-snapshot'
import App from './App'
import ErrorBoundary from './Components/ErrorBoundary'
import store from './store/store'
import history from './Utils/history'

render(
  <ErrorBoundary contactUs={false}>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
)
