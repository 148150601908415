import axios from 'axios'

const isProd = process.env.NODE_ENV === 'production'

class Logger {
  debug = isProd ? () => {} : (...rest) => console.debug(...rest)
  log = isProd ? () => {} : (...rest) => console.log(...rest)
  info = isProd ? () => {} : (...rest) => console.info(...rest)
  warn = isProd ? () => {} : (...rest) => console.warn(...rest)
  error = isProd
    ? (message, object) => {
        axios.post('/error', { message, object }).catch(() => {})
      }
    : (...rest) => {
        console.error(...rest)
      }
}

export default new Logger()
