import { detect } from 'detect-browser'
import { AdminType } from '../diagramviewer/DiagramApiDTO'
import store, { IApplicationState } from '../../store/store'
import { graphTranslations } from '../graphTranslations'
import Person from '../diagramviewer/Models/Person'
import polyglot from '../../Translator'
export const ascSortNumber = (a: number, b: number) => {
	return a - b
}
export const descSortNumber = (a: number, b: number) => {
	return b - a
}
export const shuffle = (a) => {
	for (let i = a.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1))
		;[ a[i], a[j] ] = [ a[j], a[i] ]
	}
	return a
}
export const isBrowserSupported = () => {
	const browser = detect()
	// Case where we scrape website on build with react-snap
	if (navigator.userAgent === 'ReactSnap') {
		console.log('REACT SNAP BROWSER ALLOWED.')
		return true
	}

	let isOk = true
	switch (browser && browser.name) {
		case 'ie':
			isOk = false
			break
		default:
			isOk = true
	}
	return isOk
}
export const isMobileDevice = () => {
	const browser = detect()

	let isMobile = false
	switch (browser && browser.os) {
		case 'iOS':
		case 'Android OS':
		case 'BlackBerry OS':
			isMobile = true
			break
		default:
			isMobile = false
	}
	return isMobile
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const splitLongTextInChunks = (input: string, maxLength = 20, separator = '\n') => {
	let counter: number = 0
	let result = ''
	input.split(' ').forEach((el, index) => {
		if (index !== 0) {
			result += ' '
			counter++
		}

		if (counter + el.length >= maxLength) {
			result += separator + el
			counter = el.length
		} else {
			result += el
			counter = counter + el.length
		}
	})

	return result
}

export const mergeDuplicate = (persons: Person[], duplicates: [Person, Person]) => {
	const [ a, b ] = duplicates

	let toKeep = persons.find((pers) => pers.id === a.id)
	let toDelete = persons.find((pers) => pers.id === b.id)

	// merge des childs
	toDelete.shareGroups.forEach((group) => {
		if (!toKeep.shareGroups.find((sg) => sg.target === group.target)) {
			toKeep.shareGroups.push(group)
		}
	})

	// merge des parents
	persons.forEach((person) => {
		const group = person.shareGroups.find((group) => group.target === toDelete.id)
		if (group) {
			group.target = toKeep.id
		}
	})

	// supprimer le duplicat
	persons = persons.filter((person) => person.id !== toDelete.id)

	return persons
}

export const getDuplicates = (persons: Person[]): [Person, Person] => {
	const set = new Set()
	for (const item of persons) {
		if (set.has(item.name)) {
			let original = persons.find((el) => el.name === item.name)
			return [ original, item ]
		} else set.add(item.name)
	}
}

export function unique<T = string>(input: T[]): T[] {
	return [ ...new Set(input) ]
}
