export const landingTranslations = {
  orgchartsQuick: {
    en: 'Create org charts, quickly.',
    fr: 'Créer des organigrammes, rapidement.'
  },

  buildOrgcharts: {
    en: 'Redox is a software that builds corporate org charts',
    fr: 'Redox est un logiciel qui crée des organigrammes corporatifs'
  },
  usingShareholderData: {
    en: 'using data from the shareholding of a corporation.',
    fr: "à partir des données sur l'actionnariat d'une société."
  },
  learnMore: {
    en: 'Learn More',
    fr: 'En savoir plus'
  }
}
