import React, { Suspense } from 'react'
import LoadingDots from './Diagrams/LoadingDots'

export const WaitingComponent = Component => {
  return props => (
    <Suspense fallback={<LoadingDots />}>
      <Component {...props} />
    </Suspense>
  )
}

export default WaitingComponent
