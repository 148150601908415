import axios from 'axios'
import { action, Action, State, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy'
import { find } from 'lodash'
import debounce from 'lodash/debounce'
import { generate } from 'shortid'
import { setTimeout } from 'timers'
import { APIPaths, AppRoutes, getRoute } from '../../AppRoutes'
import { DiagramLanguage, IDiagram, INote, PersonTypes } from '../../Diagrams/diagramviewer/DiagramApiDTO'
import Corporation from '../../Diagrams/diagramviewer/Models/Corporation'
import Diagram from '../../Diagrams/diagramviewer/Models/Diagram'
import Partnership from '../../Diagrams/diagramviewer/Models/Partnership'
import Person from '../../Diagrams/diagramviewer/Models/Person'
import { personFactory } from '../../Diagrams/diagramviewer/Models/personAttributer'
import PhysicalPerson from '../../Diagrams/diagramviewer/Models/PhysicalPerson'
import Trust from '../../Diagrams/diagramviewer/Models/Trust'
import { graphTranslations } from '../../Diagrams/graphTranslations'
import RealtimeDiagramManager from '../../Diagrams/RealtimeDiagramManager'
import { getDuplicates, mergeDuplicate } from '../../Diagrams/utils/utils'
import { colors } from '../../DTO/colors'
import polyglot from '../../Translator'
import { handleAxiosError } from '../../Utils/ErrorHandlingUtils'
import history from '../../Utils/history'
import store, { IApplicationModel } from '../store'
import { IAppDiagramProject } from './IAppDiagramProject'
import { getDefaultDiagramProject, getStateFromProjectDTO } from './ProjectUtils'

const debouncedUpdate = debounce(async (project: IAppDiagramProject) => {
  const { data } = await axios.put<IAppDiagramProject>(`/diagrams/${project._id}`, {
    project: project,
  })
}, 1000)

const FixDuplicatesTimeout = 1000
export interface IProjectModel extends IProjectModelActions {
  loading: boolean
  saving: boolean
  error: string

  //Display properties
  selectedSlide: number
  selectedPersonId: string
  shareDisplayParentId: string
  shareDisplayChildId: string
  edgeDisplayId: string

  //data model
  project: IAppDiagramProject
}

export interface IProjectModelActions {
  fetchProject: Thunk<IProjectModel, { diagramId: string }>
  fetchProjectBegin: Action<IProjectModel>
  fetchProjectError: Action<IProjectModel, string>
  fetchProjectSuccess: Action<IProjectModel>
  setProject: Action<IProjectModel, IAppDiagramProject>
  selectDiagram: Action<IProjectModel, number>
  addCorporation: Action<IProjectModel>
  addTrust: Action<IProjectModel>
  addPhysicalPerson: Action<IProjectModel>
  addPartnership: Action<IProjectModel>
  changeType: Action<IProjectModel, { id: string; newPerson: Person }>
  selectShares: Action<IProjectModel, { parentId: string; childId: string }>

  unSelectShares: Action<IProjectModel, { id: string }>
  updateShareholder: Action<IProjectModel, { shareholderId: string; infos: Person }>
  deleteShareholder: Action<IProjectModel, { id: string }>
  addChild: Action<IProjectModel, { index: string; childName: string }>
  addParent: Action<IProjectModel, { index: string; parentName: string }>
  removeChild: Action<IProjectModel, { parentId: string; childName: string }>
  removeParent: Action<IProjectModel, { childId: string; parentName: string }>
  setGraphBgColor: Action<IProjectModel, { index: string; color: string }>
  selectShareholder: Action<IProjectModel, { id: string }>
  unSelectShareholders: Action<IProjectModel>
  setFontFace: Action<IProjectModel, { fontFamily: string }>
  setFontColor: Action<IProjectModel, { color: string }>
  setGraphTitle: Action<IProjectModel, { title: string }>
  setGraphTitleSize: Action<IProjectModel, { size: number }>
  setProjectSaveName: Action<IProjectModel, { name: string }>
  setInitialProjectSaveName: Action<IProjectModel, { name: string }>
  setDiagramSaveName: Action<IProjectModel, { name: string }>
  toggleShowLegend: Action<IProjectModel>
  toggleShowTitle: Action<IProjectModel>
  toggleConfidentialMode: Action<IProjectModel>
  setLanguage: Action<IProjectModel, DiagramLanguage>

  setHorizontalSpacing: Action<IProjectModel, { spacing: number }>
  setVerticalSpacing: Action<IProjectModel, { spacing: number }>
  createNewFootNote: Action<IProjectModel>
  setFootNote: Action<IProjectModel, { note: INote }>
  deleteFootNote: Action<IProjectModel, { note: INote }>
  setFontSize: Action<IProjectModel, { size: number }>
  setTopPadding: Action<IProjectModel, number>
  setSidePadding: Action<IProjectModel, number>
  mergeDuplicates: Action<IProjectModel>

  addDiagram: Action<IProjectModel>
  deleteDiagram: Action<IProjectModel, { index: number }>
  copyDiagram: Action<IProjectModel, { index: number }>
  updateProjectSuccess: Action<IProjectModel>
  updateProjectBegin: Action<IProjectModel>
  updateProjectError: Action<IProjectModel, any>
  updateProject: Thunk<IProjectModel>

  postProjectSuccess: Action<IProjectModel, IAppDiagramProject>
  postProjectBegin: Action<IProjectModel>
  postProjectError: Action<IProjectModel, any>
  postProject: Thunk<IProjectModel>

  setDefaultProject: Action<IProjectModel>
  selectFirstPerson: Action<IProjectModel>

  onChangeToBeSave: ThunkOn<IProjectModel, undefined, IApplicationModel>

  // edge editing
  setColorForAllEdges: Action<IProjectModel, { color: string }>
  setOneDifferentColorForAllEdges: Action<IProjectModel>
  selectEdge: Action<IProjectModel, { groupId: string }>
  setEdgeColor: Action<IProjectModel, { color: string; groupId: string }>
  setEdgeBorder: Action<IProjectModel, { dotted: boolean; groupId: string }>
}

export type IProjectState = State<IProjectModel>

export const projectModel: IProjectModel = {
  loading: false,
  saving: false,
  error: null,

  selectedSlide: null,
  edgeDisplayId: null,
  selectedPersonId: null,
  shareDisplayParentId: null,
  shareDisplayChildId: null,

  project: getDefaultDiagramProject(),

  selectFirstPerson: action((state) => {
    const active = getActiveDiagram(state)
    const persons = active.persons
    if (persons.length) {
      state.selectedPersonId = persons[0].id
    } else {
      state.selectedPersonId = null
    }
  }),

  setDefaultProject: action((state) => {
    state.selectedSlide = 0
    state.project = getDefaultDiagramProject()
  }),

  copyDiagram: action((state, payload) => {
    const toClone = state.project.diagrams.find((el, index) => index === payload.index)
    const clone = JSON.parse(JSON.stringify(toClone)) as IDiagram

    clone.name = `${clone.name} (copy)`
    const persons = clone.persons.map((person) => personFactory(person))
    state.project.diagrams.push({ ...clone, persons: persons })
    RealtimeDiagramManager.updateProject(state.project)
  }),

  deleteDiagram: action((state, payload) => {
    let selectedSlide
    if (state.project.diagrams.length === 1) {
      selectedSlide = null
    } else {
      selectedSlide = state.project.diagrams.length - 2
    }

    state.selectedSlide = selectedSlide
    state.project.diagrams = state.project.diagrams.filter((el, index) => index !== payload.index)

    RealtimeDiagramManager.updateProject(state.project)

    return state
  }),

  addDiagram: action((state, payload) => {
    state.selectedSlide = state.project.diagrams.length
    state.project.diagrams.push(
      new Diagram({
        name: `${polyglot.t('organizationalChart')} ${state.project.diagrams.length + 1}`,
      })
    )

    RealtimeDiagramManager.updateProject(state.project)
  }),

  updateProject: thunk(async (actions, payload, { getState }) => {
    try {
      actions.updateProjectBegin()
      const { project } = getState()
      debouncedUpdate(project)
      actions.updateProjectSuccess()
    } catch (err) {
      if (!handleAxiosError(err.response)) {
        actions.updateProjectError(err.response.data)
      }
    }
  }),

  updateProjectSuccess: action((state, payload) => {
    state.saving = false
    state.error = null
  }),

  updateProjectBegin: action((state, payload) => {
    state.saving = true
  }),

  updateProjectError: action((state, payload) => {
    console.error(payload)
    state.saving = false
    state.error = payload
  }),

  postProject: thunk(async (actions, payload, { getState }) => {
    try {
      actions.postProjectBegin()
      const project = getState().project
      const { data } = await axios.post<IAppDiagramProject>(`/diagrams`, project)
      actions.postProjectSuccess(data)
      history.push(getRoute(`${AppRoutes.FILL_DIAGRAMS}/${data._id}`))
    } catch (err) {
      if (!handleAxiosError(err.response)) {
        actions.postProjectError(err.toString())
      }
    }
  }),

  postProjectSuccess: action((state, payload) => {
    state.saving = false
    state.error = null
    state.project = getStateFromProjectDTO(payload)
  }),

  postProjectBegin: action((state, payload) => {
    state.saving = true
  }),

  postProjectError: action((state, payload) => {
    state.saving = false
    state.error = payload
  }),

  fetchProject: thunk(async (actions, { diagramId }, { getState }) => {
    try {
      actions.fetchProjectBegin()
      const { data } = await axios.get<IAppDiagramProject>(`${APIPaths.GET_DIAGRAM}/${diagramId}`)
      actions.setProject(data)
      actions.fetchProjectSuccess()
    } catch (error) {
      if (!handleAxiosError(error.response)) {
        actions.fetchProjectError(error.toString())
      }
    }
  }),

  fetchProjectBegin: action((state, payload) => {
    state.loading = true
    state.error = null
  }),

  fetchProjectError: action((state, payload) => {
    state.loading = false
    state.error = payload
  }),

  fetchProjectSuccess: action((state) => {
    state.selectedSlide = state.project.diagrams.length ? 0 : null
    state.loading = false
  }),

  setProject: action((state, payload) => {
    state.project = getStateFromProjectDTO(payload)
    if (state.project.diagrams.length === 0) {
      state.selectedSlide = null
    } else if (state.selectedSlide >= state.project.diagrams.length) {
      state.selectedSlide = state.project.diagrams.length - 1
    }
  }),

  selectDiagram: action((state, payload) => {
    state.selectedSlide = payload
  }),

  addCorporation: action((state, payload) => {
    const persons = getActiveDiagram(state).persons
    const number = persons.filter((el) => el.type === PersonTypes.CORPORATION).length + 1
    const newCorpo = new Corporation().setName(`Corporation ${number}`)
    persons.push(newCorpo)
    state.selectedPersonId = newCorpo.id
    RealtimeDiagramManager.updateProject(state.project)
  }),

  addTrust: action((state, payload) => {
    const number = getActiveDiagram(state).persons.filter((el) => el.type === PersonTypes.TRUST).length + 1
    const newTrust = new Trust().setName(`${polyglot.tr(graphTranslations.trust)} ${number}`)
    getActiveDiagram(state).persons.push(newTrust)
    state.selectedPersonId = newTrust.id
    RealtimeDiagramManager.updateProject(state.project)
  }),

  addPhysicalPerson: action((state, payload) => {
    const number = getActiveDiagram(state).persons.filter((el) => el.type === PersonTypes.PERSON).length + 1
    const newPerson = new PhysicalPerson().setName(`${polyglot.tr(graphTranslations.physicalPerson)} ${number}`)
    getActiveDiagram(state).persons.push(newPerson)
    state.selectedPersonId = newPerson.id
    RealtimeDiagramManager.updateProject(state.project)
  }),

  addPartnership: action((state, payload) => {
    const number = getActiveDiagram(state).persons.filter((el) => el.type === PersonTypes.PARTNERSHIP).length + 1
    const newPerson = new Partnership().setName(`${polyglot.tr(graphTranslations.partnership)} ${number}`)
    getActiveDiagram(state).persons.push(newPerson)
    state.selectedPersonId = newPerson.id
    RealtimeDiagramManager.updateProject(state.project)
  }),

  changeType: action((state, payload) => {
    getActiveDiagram(state).persons = getActiveDiagram(state).persons.map((person) =>
      person.id === payload.id ? payload.newPerson : person.clone()
    )
    RealtimeDiagramManager.updateProject(state.project)
  }),

  selectShares: action((state, { parentId, childId }) => {
    state.shareDisplayParentId = parentId
    state.shareDisplayChildId = childId
  }),

  unSelectShares: action((state, payload) => {
    state.shareDisplayParentId = null
    state.shareDisplayChildId = null
  }),

  updateShareholder: action((state, payload) => {
    const { shareholderId, infos } = payload
    const updatedPersons = getActiveDiagram(state).persons.map((person) => {
      if (person.id === shareholderId) {
        return personFactory(infos)
      } else {
        return person
      }
    })
    const duplicates = getDuplicates(updatedPersons)
    if (duplicates) {
      setTimeout(() => {
        store.dispatch.project.mergeDuplicates()
      }, FixDuplicatesTimeout)
    }
    getActiveDiagram(state).persons = updatedPersons
    RealtimeDiagramManager.updateProject(state.project)
  }),

  deleteShareholder: action((state, payload) => {
    const { id } = payload

    const persons = getActiveDiagram(state).persons.filter((person) => person.id !== id)

    persons.forEach((person) => {
      person.shareGroups = person.shareGroups.filter((group) => group.target !== id)
    })

    getActiveDiagram(state).persons = persons

    RealtimeDiagramManager.updateProject(state.project)
  }),

  addChild: action((state, payload) => {
    const { childName, index } = payload
    const child = new Corporation()

    child.name = childName

    const updatedPersons = getActiveDiagram(state)
      .persons.map((person) => {
        if (person.id === index) {
          return person.clone().setChild(child.id)
        } else return person.clone()
      })
      .concat([child])
    getActiveDiagram(state).persons = updatedPersons

    const duplicates = getDuplicates(updatedPersons)

    if (duplicates) {
      setTimeout(() => {
        store.dispatch.project.mergeDuplicates()
      }, FixDuplicatesTimeout)
    }

    RealtimeDiagramManager.updateProject(state.project)
  }),
  addParent: action((state, payload) => {
    const { parentName, index } = payload
    const parent = new Corporation()

    parent.name = parentName

    const updatedPersons = getActiveDiagram(state)
      .persons.map((person) => {
        if (person.id === index) {
          parent.setChild(person.id)
          return person.clone()
        } else return person.clone()
      })
      .concat([parent])
    getActiveDiagram(state).persons = updatedPersons

    const duplicates = getDuplicates(updatedPersons)
    if (duplicates) {
      setTimeout(() => {
        store.dispatch.project.mergeDuplicates()
      }, FixDuplicatesTimeout)
    }

    RealtimeDiagramManager.updateProject(state.project)
  }),

  mergeDuplicates: action((state, payload) => {
    let persons = getActiveDiagram(state).persons
    while (getDuplicates(persons)) {
      const duplicates = getDuplicates(persons)
      persons = !duplicates ? persons : mergeDuplicate(persons, duplicates)
    }

    getActiveDiagram(state).persons = persons
    RealtimeDiagramManager.updateProject(state.project)
  }),

  removeChild: action((state, payload) => {
    const child = getActiveDiagram(state).persons.find((person) => person.name === payload.childName)
    if (child) {
      const newPersons = getActiveDiagram(state).persons.map((person) => {
        if (person.id === payload.parentId) {
          const clone = person.clone().deleteChild(child.id)
          return clone
        } else if (person === child) {
          return child.clone()
        } else {
          return person
        }
      })
      getActiveDiagram(state).persons = newPersons
      RealtimeDiagramManager.updateProject(state.project)
    } else {
    }
  }),
  removeParent: action((state, payload) => {
    const parent = getActiveDiagram(state).persons.find((person) => person.name === payload.parentName)
    if (parent) {
      const newPersons = getActiveDiagram(state).persons.map((person) => {
        if (person.id === payload.childId) {
          const clone = person.clone()
          return clone
        } else if (person === parent) {
          return parent.clone().deleteChild(payload.childId)
        } else {
          return person
        }
      })
      getActiveDiagram(state).persons = newPersons
      RealtimeDiagramManager.updateProject(state.project)
    } else {
    }
  }),

  setGraphBgColor: action((state, payload) => {
    const newPersons = getActiveDiagram(state).persons.map((person) => {
      if (person.id === payload.index) {
        return person.clone().setBgColor(payload.color)
      } else return person.clone()
    })
    getActiveDiagram(state).persons = newPersons
    RealtimeDiagramManager.updateProject(state.project)
  }),

  selectShareholder: action((state, payload) => {
    state.selectedPersonId = payload.id
  }),
  unSelectShareholders: action((state, payload) => {
    state.selectedPersonId = null
  }),

  setFontFace: action((state, payload) => {
    getActiveDiagram(state).fontFamily = payload.fontFamily
    RealtimeDiagramManager.updateProject(state.project)
  }),

  setFontSize: action((state, payload) => {
    getActiveDiagram(state).fontSize = payload.size
    RealtimeDiagramManager.updateProject(state.project)
  }),

  setTopPadding: action((state, payload) => {
    getActiveDiagram(state).topPadding = payload
    RealtimeDiagramManager.updateProject(state.project)
  }),

  setSidePadding: action((state, payload) => {
    getActiveDiagram(state).sidePadding = payload
    RealtimeDiagramManager.updateProject(state.project)
  }),

  setFontColor: action((state, payload) => {
    getActiveDiagram(state).fontColor = payload.color
    RealtimeDiagramManager.updateProject(state.project)
  }),

  setGraphTitle: action((state, payload) => {
    getActiveDiagram(state).title.value = payload.title
    RealtimeDiagramManager.updateProject(state.project)
  }),

  setGraphTitleSize: action((state, payload) => {
    getActiveDiagram(state).title.size = payload.size
    RealtimeDiagramManager.updateProject(state.project)
  }),

  setProjectSaveName: action((state, payload) => {
    state.project.name = payload.name
    RealtimeDiagramManager.updateProject(state.project)
  }),
  setInitialProjectSaveName: action((state, payload) => {
    state.project.name = payload.name
    RealtimeDiagramManager.updateProject(state.project)
  }),

  toggleShowTitle: action((state, payload) => {
    getActiveDiagram(state).showTitle = !getActiveDiagram(state).showTitle
  }),

  toggleShowLegend: action((state, payload) => {
    getActiveDiagram(state).showLegend = !getActiveDiagram(state).showLegend
  }),
  setDiagramSaveName: action((state, payload) => {
    getActiveDiagram(state).name = payload.name
    RealtimeDiagramManager.updateProject(state.project)
  }),

  toggleConfidentialMode: action((state, payload) => {
    getActiveDiagram(state).confidentialMode = !getActiveDiagram(state).confidentialMode
    RealtimeDiagramManager.updateProject(state.project)
  }),

  setLanguage: action((state, payload) => {
    getActiveDiagram(state).language = payload
    RealtimeDiagramManager.updateProject(state.project)
  }),

  setHorizontalSpacing: action((state, payload) => {
    getActiveDiagram(state).horizontalSpacing = payload.spacing
    RealtimeDiagramManager.updateProject(state.project)
  }),

  setVerticalSpacing: action((state, payload) => {
    getActiveDiagram(state).verticalSpacing = payload.spacing
    RealtimeDiagramManager.updateProject(state.project)
  }),

  setFootNote: action((state, payload) => {
    const updatedNotes = getActiveDiagram(state).footNotes.map((note) => {
      if (payload.note.id === note.id) {
        return payload.note
      } else return note
    })
    getActiveDiagram(state).footNotes = updatedNotes
    RealtimeDiagramManager.updateProject(state.project)
  }),

  deleteFootNote: action((state, payload) => {
    const updatedNotes = getActiveDiagram(state).footNotes.filter((note) => note.id !== payload.note.id)
    getActiveDiagram(state).footNotes = updatedNotes
    RealtimeDiagramManager.updateProject(state.project)
  }),

  createNewFootNote: action((state, payload) => {
    getActiveDiagram(state).footNotes.push({ id: generate(), note: 'New note' })
    RealtimeDiagramManager.updateProject(state.project)
  }),

  onChangeToBeSave: thunkOn(
    (actions, storeActions) => [
      storeActions.project.addCorporation,
      storeActions.project.addTrust,
      storeActions.project.addPhysicalPerson,
      storeActions.project.addPartnership,
      storeActions.project.changeType,
      storeActions.project.updateShareholder,
      storeActions.project.deleteShareholder,
      storeActions.project.addChild,
      storeActions.project.addParent,
      storeActions.project.removeChild,
      storeActions.project.removeParent,
      storeActions.project.setGraphBgColor,
      storeActions.project.setFontFace,
      storeActions.project.setFontColor,
      storeActions.project.setTopPadding,
      storeActions.project.setSidePadding,
      storeActions.project.setGraphTitle,
      storeActions.project.setGraphTitleSize,
      storeActions.project.setProjectSaveName,
      storeActions.project.setDiagramSaveName,
      storeActions.project.setEdgeColor,
      storeActions.project.setEdgeBorder,
      storeActions.project.setHorizontalSpacing,
      storeActions.project.setVerticalSpacing,
      storeActions.project.toggleShowTitle,
      storeActions.project.toggleShowLegend,
      storeActions.project.createNewFootNote,
      storeActions.project.setFootNote,
      storeActions.project.deleteFootNote,
      storeActions.project.mergeDuplicates,
      storeActions.project.addDiagram,
      storeActions.project.deleteDiagram,
      storeActions.project.copyDiagram,
      storeActions.project.setOneDifferentColorForAllEdges,
      storeActions.project.setColorForAllEdges,
      storeActions.project.setLanguage,
    ],
    async (actions, payload) => actions.updateProject()
  ),
  // edge editing
  selectEdge: action((state, { groupId }) => {
    state.edgeDisplayId = groupId
  }),
  setEdgeColor: action((state, { color, groupId }) => {
    const parent: Person = find(getActiveDiagram(state).persons, { shareGroups: [{ id: groupId }] })
    const newPersons = getActiveDiagram(state).persons.map((person) => {
      if (person.id === parent.id) {
        const updatedGroups = person.shareGroups.map((group) => {
          if (group.id === groupId) {
            return Object.assign(group, { color: color })
          } else return group
        })
        return Object.assign(person, { shareGroups: updatedGroups })
      } else return person
    })
    getActiveDiagram(state).persons = newPersons

    RealtimeDiagramManager.updateProject(state.project)
  }),
  setEdgeBorder: action((state, { dotted, groupId }) => {
    const parent: Person = find(getActiveDiagram(state).persons, { shareGroups: [{ id: groupId }] })
    const newPersons = getActiveDiagram(state).persons.map((person) => {
      if (person.id === parent.id) {
        const updatedGroups = person.shareGroups.map((group) => {
          if (group.id === groupId) {
            return Object.assign(group, { dotted: dotted })
          } else return group
        })
        return Object.assign(person, { shareGroups: updatedGroups })
      } else return person
    })
    getActiveDiagram(state).persons = newPersons

    RealtimeDiagramManager.updateProject(state.project)
  }),
  setColorForAllEdges: action((state, { color }) => {
    const persons = getActiveDiagram(state).persons
    persons.forEach((p) => p.shareGroups.forEach((g) => (g.color = null)))
    return persons.forEach((p) => (p.edgeColor = color))
  }),
  setOneDifferentColorForAllEdges: action((state) => {
    const persons = getActiveDiagram(state).persons
    persons.forEach((p) => p.shareGroups.forEach((g) => (g.color = null)))
    return persons.forEach((p, index) => (p.edgeColor = colors[index]))
  }),
}

export const getActiveDiagram = (state: IProjectState) => state.project.diagrams[state.selectedSlide]
