import moment from 'moment'
import polyglot from '../../Translator'
import { GraphStatus } from '../../Diagrams/GraphStatus'
import { graphTranslations } from '../../Diagrams/graphTranslations'
import Diagram from '../../Diagrams/diagramviewer/Models/Diagram'
import { IAppDiagramProject } from './IAppDiagramProject'
import { merge } from 'lodash'

export const getDefaultDiagramProject = (): IAppDiagramProject => {
	return {
		name: `${(polyglot && polyglot.tr(graphTranslations.newProject)) || 'Diagram project'}`,
		createdAt: moment().toDate(),
		status: GraphStatus.InProgress,
		diagrams: [ new Diagram() ]
	}
}

export const getStateFromProjectDTO = (state: IAppDiagramProject): IAppDiagramProject => {
	state.diagrams = state.diagrams.map((diagram) => {
		return merge(diagram, new Diagram(diagram))
	})
	return state
}
