import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const LandingGrid = styled.div`
	background-color: transparent;
	display: grid;
	grid-gap: 10px;
	height: 100%;
	padding-left: 10px;
	padding-right: 10px;
	grid-template-areas: 'title' 'img';
	grid-template-rows: 1fr 1fr;
	grid-template-columns: 1fr;
	background-image: linear-gradient(176deg, #ffffff 48%, #22313f calc(48% + 2px));

	@media (min-width: 450px) {
		grid-template-areas: 'title' 'img';
		grid-template-rows: 1fr 2fr;
		grid-template-columns: 1fr;
	}
`
export const MainTitleLanding = styled.h1`
	font-weight: 300;
	font-size: 45px;
	margin: 20px;
	@media (max-width: 321px) {
		font-weight: 300;
		font-size: 32px;
		margin: 10px;
	}
`

export const ParagraphLanding = styled.div`
	color: #777777;
	font-weight: 400;
	font-size: 22px;
	margin-bottom: 30px;
	@media (max-width: 321px) {
		font-size: 16px;
	}
`
export const TitleBoxLanding = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	grid-area: title;
	text-align: center;
	@media (max-width: 321px) {
		padding-top: 40px;
	}
`
export const ImageLanding = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	grid-area: img;
	@media (max-width: 376px) {
		height: 0%;
		width: 0%;
	}
`

export const StyledLinkLanding = styled(Link)`
  color: #ffffff;
  font-size: 14px;
  padding: 15px 30px;
  border: none;
  border-radius: 3px;
  background-color: #29c086;
  &:hover {
    background-color: #229e6d;
  }
`
