import cookies, { setCookie } from './Utils/cookies'
import history from './Utils/history'

export type Language = 'fr' | 'en'

class LanguageManager {
  public setUserLanguage = (lang: 'fr' | 'en') => {
    history.push(history.location.pathname.replace(this.getUserLanguage(), lang))
    this.setLanguageCookie(lang)
  }

  public getStringUrlLanguage = (value?: string) => {
    const match = /\/([a-z]{2})\//g.exec(value)

    if (match && this.isKnownLanguage(match[1])) {
      const lang: Language = match[1] as Language
      return lang
    } else {
      return null
    }
  }

  public getUrlLanguage = () => {
    return this.getStringUrlLanguage(history.location.pathname)
  }

  public getUserLanguage = (): Language => {
    if (this.getUrlLanguage()) {
      this.setLanguageCookie(this.getUrlLanguage())
      return this.getUrlLanguage()
    }

    const langcookie = cookies.get('language')

    if (langcookie === 'en' || langcookie === 'fr') {
      return langcookie
    } else {
      const lang = navigator.language.slice(0, 2)
      let language: Language
      if (lang === 'fr') {
        language = 'fr'
      } else if (lang === 'en') {
        language = 'en'
      } else {
        language = 'en'
      }
      this.setLanguageCookie(language)
      return lang === 'fr' || lang === 'en' ? lang : 'en'
    }
  }

  public getOppositeLanguage = () => {
    const lang = this.getUserLanguage()
    if (lang === 'en') {
      return 'fr'
    } else {
      return 'en'
    }
  }

  private setLanguageCookie = (lang: Language) => {
    setCookie('language', lang)
  }

  public isKnownLanguage = (lang: string) => {
    return lang === 'fr' || lang === 'en'
  }

  public getOppositeLanguageUrl = () => {
    return history.location.pathname.replace(this.getUserLanguage(), this.getOppositeLanguage())
  }
}

export const languageManager = new LanguageManager()
export default languageManager
