import * as React from 'react'
import { IPartnership, PersonTypes } from '../DiagramApiDTO'
import Person from './Person'
import { ITextWithDimensions, textDimensions, FontWeight } from '../textDimensions'
import { generate } from 'shortid'
import { wrapText, getHangingFontOffset } from '../utils'

export class Partnership extends Person {
	type = PersonTypes.PARTNERSHIP

	static ABRatio = 3

	constructor(args: Partial<IPartnership> = {}) {
		super(args)
	}
	getInfosToBeDisplayed(): ITextWithDimensions[] {
		const { fontSize, fontFamily } = this.diagram.getLayoutProperties()
		const nameLines = wrapText(this.name, 20)
		const nameTexts = nameLines.map((nameLine) => ({
			text: nameLine,
			fontSize,
			fontFamily,
			fontWeight: FontWeight.Bold
		}))

		return nameTexts.map((text) => textDimensions(text))
	}

	computeSelfDimensions() {
		const dims = this.getContentDimensions()

		const w = dims.width
		const h = dims.height
		const r = Partnership.ABRatio

		const a = Math.sqrt(h ** 2 * r ** 2 + w ** 2) / 2
		const b = a / r

		this.width = 2 * a
		this.height = 2 * b
	}

	renderTextToBeDisplayed(): JSX.Element[] {
		const infos = this.getInfosToBeDisplayed()
		const { topPadding, textSpacing, shapesBorderWidth } = this.diagram.getLayoutProperties()

		const dims = this.getContentDimensions()

		let x = this.x + this.width / 2
		let y = this.y + this.height / 2 - dims.height / 2 + topPadding + shapesBorderWidth

		let elements: JSX.Element[] = []
		for (let info of infos) {
			elements.push(
				<text
					key={generate()}
					className="svgText"
					x={x}
					textAnchor="middle"
					y={y + getHangingFontOffset(info)}
					fontSize={info.fontSize}
					fontFamily={info.fontFamily}
					fontWeight={info.fontWeight || FontWeight.Normal}
					fill={this.diagram.fontColor}
				>
					{info.text}
				</text>
			)
			y += info.height + textSpacing
		}
		return elements
	}

	render(onClick: (nodeId: string) => void, selectedNodeId: string): JSX.Element {
		const { shapesBorderWidth } = this.diagram.getLayoutProperties()
		const bgColor: string = selectedNodeId === this.id ? Person.SelectedColor : this.bgColor
		return (
			<g
				className="box"
				key={this.id}
				onClick={(e) => {
					e.stopPropagation()
					onClick(this.id)
				}}
				id={this.id}
			>
				<ellipse
					cx={this.x + this.width / 2}
					cy={this.y + this.height / 2}
					ry={this.height / 2}
					rx={this.width / 2}
					style={{ fill: bgColor, strokeWidth: shapesBorderWidth, stroke: 'black' }}
				/>
				{this.renderTextToBeDisplayed()}
			</g>
		)
	}
}

export default Partnership
