export const colors = [
	'#000000',
	'#ff8700',
	'#5f00ff',
	'#ff0000',
	'#ffd700',
	'#800000',
	'#00875f',
	'#d700af',
	'#008000',
	'#a7003f',
	'#005fff',
	'#ff87af',
	'#00af5f',
	'#005fff',
	'#afd700',
	'#800080',
	'#875f00',
	'#00afff',
	'#875faf',
	'#00af5f',
	'#afaf5f',
	'#5f0087',
	'#008080',
	'#808080',
	'#00ff00',
	'#005f5f',
	'#ff00ff',
	'#af5f00',
	'#00d7af',
	'#ffaf5f',
	'#ff005f',
	'#000000',
	'#ff8700',
	'#5f00ff',
	'#ff0000',
	'#ffd700',
	'#800000',
	'#00875f',
	'#d700af',
	'#008000',
	'#a7003f',
	'#005fff',
	'#ff87af',
	'#00af5f',
	'#005fff',
	'#afd700',
	'#800080',
	'#875f00',
	'#00afff',
	'#875faf',
	'#00af5f',
	'#afaf5f',
	'#5f0087',
	'#008080',
	'#808080',
	'#00ff00',
	'#005f5f',
	'#ff00ff',
	'#af5f00',
	'#00d7af',
	'#ffaf5f',
	'#ff005f',
	'#000000',
	'#ff8700',
	'#5f00ff',
	'#ff0000',
	'#ffd700',
	'#800000',
	'#00875f',
	'#d700af',
	'#008000',
	'#a7003f',
	'#005fff',
	'#ff87af',
	'#00af5f',
	'#005fff',
	'#afd700',
	'#800080',
	'#875f00',
	'#00afff',
	'#875faf',
	'#00af5f',
	'#afaf5f',
	'#5f0087',
	'#008080',
	'#808080',
	'#00ff00',
	'#005f5f',
	'#ff00ff',
	'#af5f00',
	'#00d7af',
	'#ffaf5f',
	'#ff005f'
];
