export enum ErrorMessages {
	NO_ADMIN_ERROR = 'NO_ADMIN',
	NO_LAWFIRM = 'NO_LAWFIRM',
	OTHER_LAWFIRM = 'OTHER_LAWFIRM',

	/* LOGIN ERRORS */
	USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
	USER_NOT_FOUND = 'USER_NOT_FOUND',
	INVALID_PASSWORD = 'INVALID_PASSWORD',
	USER_NOT_SIGNED_IN = 'USER_NOT_SIGNED_IN',
	EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED',

	// Anti theft Errors
	USER_MANY_SESSIONS = 'USER_MANY_SESSIONS',

	// PASSWORD RESET ERRORS
	LINK_EXPIRED = 'LINK_EXPIRED',
	UNEXISTING_LINK = 'UNEXISTING_LINK',

	// LAWFIRM INVITES
	INVITE_NOT_FOUND = 'INVITE_NOT_FOUND'
}

export default ErrorMessages;
