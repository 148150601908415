import { immerable } from 'immer'
import { flatten, maxBy, sumBy } from 'lodash'
import * as React from 'react'
import { generate } from 'shortid'
import { DiagramLanguage, IShare, IShareGroup, PersonTypes, ShareRights, ShareType } from '../DiagramApiDTO'
import { IGraphNode } from '../IGraphNode'
import { ITextRequest, ITextWithDimensions, textDimensions, FontWeight } from '../textDimensions'
import { formatLargeNumber, textChunksInArray, translate, wrapText, getHangingFontOffset } from '../utils'
import Diagram from './Diagram'
import Share from './Share'

const translations = {
	beneficiary: {
		en: 'Beneficiary',
		fr: 'Bénéficiaire'
	}
}

export class ShareGroup implements IGraphNode, IShareGroup {
	id: string
	y: number
	x: number
	width: number
	height: number
	target: string = null
	dotted: boolean = false
	color: string = null
	shares: IShare[] = []

	private diagram: Diagram;

	[immerable] = true
	static SharePrefix = 'actions-'

	constructor(parentId: string, args: Partial<IShareGroup> = {}) {
		this.target = args.target || this.target
		this.color = args.color || this.color
		this.dotted = args.dotted || this.dotted
		this.shares = args.shares ? args.shares.map((share) => new Share(share)) : this.shares
		this.id = `${ShareGroup.SharePrefix}${parentId}-${this.target}`
	}

	setColor(): string {
		let parentColor: string
		this.diagram.persons.map((person) => {
			if (person.shareGroups.find((g) => g.id === this.id)) {
				parentColor = person.edgeColor
				const group = person.shareGroups.find((g) => g.id === this.id)
				this.color = group.color
			}
		})

		return this.color ? this.color : parentColor
	}

	getInfosToBeDisplayed(): ITextWithDimensions[] {
		const targetPerson = this.diagram.persons.find((pers) => pers.id === this.target)
		switch (targetPerson.type) {
			case PersonTypes.CORPORATION:
				if (this.shares.length) {
					return flatten(this.shares.map((el, index) => this.renderCorpoShare(el, index)))
				} else return this.renderCorpoEmptyShare()

			case PersonTypes.PARTNERSHIP:
				if (this.shares.length) {
					return flatten(this.shares.map((el) => this.renderPartnershipShare(el)))
				} else return this.renderPartnershipEmptyShare()

			case PersonTypes.TRUST:
				return this.renderTrustShare()

			default:
				return []
		}
	}

	renderTrustShare(): ITextWithDimensions[] {
		const { smallFont, fontFamily } = this.diagram.getLayoutProperties()
		return [
			textDimensions({
				text: translate(translations.beneficiary, this.diagram.getLanguage()),
				fontSize: smallFont,
				fontFamily: fontFamily
			})
		]
	}

	renderCorpoShare(share: IShare, index: number): ITextWithDimensions[] {
		const confidentialMode: boolean = this.diagram.confidentialMode
		const diagram = this.diagram
		const isFrench = diagram.language === DiagramLanguage.FR
		const { smallFont, fontFamily } = this.diagram.getLayoutProperties()

		const certificateNumber = share.certificateNumber
			? `${isFrench ? 'Certificat' : 'Certificate'}: ${share.certificateNumber}`
			: null

		const translations = {
			pbr: {
				en: 'ACB',
				fr: 'PBR'
			},
			vr: {
				en: 'RV',
				fr: 'VR'
			},
			cv: {
				en: 'PUC',
				fr: 'CV'
			},
			jvm: {
				en: 'FMV',
				fr: 'JVM'
			}
		}

		const nb = share.amount ? `${formatLargeNumber(share.amount, this.diagram.getLanguage())}` : null

		const pbr = share.pbr
			? `${translate(translations.pbr, diagram.getLanguage())}: ${formatLargeNumber(
					share.pbr,
					this.diagram.getLanguage()
				)} $`
			: null

		const vr = share.vr
			? `${translate(translations.vr, diagram.getLanguage())}: ${formatLargeNumber(
					share.vr,
					this.diagram.getLanguage()
				)} $`
			: null

		const cv = share.cv
			? `${translate(translations.cv, diagram.getLanguage())}: ${formatLargeNumber(
					share.cv,
					this.diagram.getLanguage()
				)} $`
			: null

		const jvm = share.jvm
			? `${translate(translations.jvm, diagram.getLanguage())}: ${formatLargeNumber(
					share.jvm,
					this.diagram.getLanguage()
				)} $`
			: null

		const cat = share.category ? `'${share.category}'` : null
		const percentage = share.percentage ? `(${share.percentage}%)` : null
		const other = !confidentialMode && share.other ? textChunksInArray(wrapText(share.other, 35, '')[0]) : []

		const participatingRight = () => {
			let participatingString
			if (isFrench) {
				if (share.participating === ShareRights.NA) {
					return null
				} else if (share.participating === ShareRights.ON) {
					participatingString = 'participantes'
				} else return (participatingString = 'non-participantes')
				return participatingString
			} else {
				if (share.participating === ShareRights.NA) {
					return null
				} else if (share.participating === ShareRights.ON) {
					participatingString = 'participating'
				} else return (participatingString = 'non-participating')
				return participatingString
			}
		}

		const votingRight = () => {
			let votingString
			if (isFrench) {
				if (share.voting === ShareRights.NA) {
					return null
				} else if (share.voting === ShareRights.ON) {
					votingString = 'votantes'
				} else return (votingString = 'non-votantes')
				return votingString
			} else {
				if (share.voting === ShareRights.NA) {
					return null
				} else if (share.voting === ShareRights.ON) {
					votingString = 'voting'
				} else return (votingString = 'non-voting')
				return votingString
			}
		}

		const dividendRight = () => {
			let dividendString

			if (isFrench) {
				if (share.dividend === ShareRights.NA) {
					return null
				} else if (share.dividend === ShareRights.ON) {
					dividendString = 'avec dividendes'
				} else return (dividendString = 'sans dividendes')
				return dividendString
			} else {
				if (share.dividend === ShareRights.NA) {
					return null
				} else if (share.dividend === ShareRights.ON) {
					dividendString = 'with dividends'
				} else return (dividendString = 'without dividends')
				return dividendString
			}
		}

		const shareGeneralInfos = () => {
			let shareGeneralInfosString = ''
			if (isFrench) {
				const type =
					share.type === ShareType.ORDINARY
						? 'ordinaires'
						: share.type === ShareType.PREFERRED
							? 'privilégiées'
							: share.type === ShareType.CONTROL ? 'de contrôle' : share.type === ShareType.ROLLOVER ? 'de roulement' : null

				if (nb) {
					shareGeneralInfosString += ` ${nb}`
				}
				if (nb || type || cat || percentage) {
					shareGeneralInfosString += ` actions`
				}
				if (type) {
					shareGeneralInfosString += ` ${type}`
				}
				if (cat) {
					shareGeneralInfosString += ` ${cat}`
				}
				if (percentage) {
					shareGeneralInfosString += ` ${percentage}`
				}
			} else {
				const type =
					share.type === ShareType.ORDINARY
						? 'ordinary'
						: share.type === ShareType.PREFERRED
							? 'privileged'
							: share.type === ShareType.CONTROL ? 'control' : share.type === ShareType.ROLLOVER ? 'rollover' : null

				if (nb) {
					shareGeneralInfosString += ` ${nb}`
				}

				if (type) {
					shareGeneralInfosString += ` ${type}`
				}
				if (nb || type || cat || percentage) {
					shareGeneralInfosString += ` shares`
				}

				if (cat) {
					shareGeneralInfosString += ` ${cat}`
				}
				if (percentage) {
					shareGeneralInfosString += ` ${percentage}`
				}
			}

			return shareGeneralInfosString
		}

		const infos: ITextRequest[] = [
			{
				text: !confidentialMode && index ? '―' : null,
				fontSize: smallFont,
				fontFamily: fontFamily
			},
			{
				text: shareGeneralInfos(),
				fontSize: smallFont,
				fontFamily: fontFamily,
				fontWeight: FontWeight.Bold
			},
			{
				text: !confidentialMode && participatingRight(),
				fontSize: smallFont,
				fontFamily: fontFamily
			},
			{
				text: !confidentialMode && votingRight(),
				fontSize: smallFont,
				fontFamily: fontFamily
			},
			{
				text: !confidentialMode && dividendRight(),
				fontSize: smallFont,
				fontFamily: fontFamily
			},
			{
				text: !confidentialMode && certificateNumber,
				fontSize: smallFont,
				fontFamily: fontFamily
			},
			{
				text: !confidentialMode && pbr,
				fontSize: smallFont,
				fontFamily: fontFamily
			},
			{
				text: !confidentialMode && cv,
				fontSize: smallFont,
				fontFamily: fontFamily
			},
			{
				text: !confidentialMode && jvm,
				fontSize: smallFont,
				fontFamily: fontFamily
			},
			{
				text: !confidentialMode && vr,
				fontSize: smallFont,
				fontFamily: fontFamily
			},

			...other.map((o) => ({
				text: o,
				fontSize: smallFont,
				fontFamily: fontFamily
			}))
		]

		return infos.filter((el) => el.text && el.text.length).map((info) => textDimensions(info))
	}

	renderCorpoEmptyShare(): ITextWithDimensions[] {
		const diagram = this.diagram
		const isFrench = diagram.language === DiagramLanguage.FR
		const { smallFont, fontFamily } = this.diagram.getLayoutProperties()
		const d: ITextRequest = {
			text: isFrench ? 'Action' : 'Share',
			fontSize: smallFont,
			fontFamily: fontFamily
		}

		return [ textDimensions(d) ]
	}

	renderPartnershipEmptyShare(): ITextWithDimensions[] {
		const { smallFont, fontFamily } = this.diagram.getLayoutProperties()
		const d: ITextRequest = {
			text: 'Participation',
			fontSize: smallFont,
			fontFamily: fontFamily
		}
		return [ textDimensions(d) ]
	}

	renderPartnershipShare(share: IShare): ITextWithDimensions[] {
		const { smallFont, fontFamily } = this.diagram.getLayoutProperties()
		const percentage = share.percentage ? `${share.percentage}%` : null
		const other = share.other ? textChunksInArray(wrapText(share.other, 35, '')[0]) : []

		const infos: ITextRequest[] = [
			{
				text: percentage,
				fontSize: smallFont,
				fontFamily: fontFamily
			},
			...other.map((o) => ({
				text: o,
				fontSize: smallFont,
				fontFamily: fontFamily
			}))
		]

		return infos.filter((el) => el.text && el.text.length).map((info) => textDimensions(info))
	}

	computeDimensions(diagram: Diagram) {
		this.diagram = diagram
		const { topPadding, sidePadding, textSpacing } = this.diagram.getLayoutProperties()
		const textElements = this.getInfosToBeDisplayed()
		this.width = textElements.length && maxBy(textElements, (item) => item.width).width + 2 * sidePadding

		this.height =
			textElements.length &&
			sumBy(textElements, (item) => item.height) + (textElements.length - 1) * textSpacing + 2 * topPadding
	}

	renderTextToBeDisplayed(): JSX.Element[] {
		const infos = this.getInfosToBeDisplayed()
		const { topPadding, textSpacing, shapesBorderWidth } = this.diagram.getLayoutProperties()
		let x = this.x + this.width / 2
		let y = this.y + topPadding + shapesBorderWidth
		let elements: JSX.Element[] = []
		for (let info of infos) {
			elements.push(
				<text
					key={generate()}
					className="svgText"
					x={x}
					y={y + getHangingFontOffset(info)}
					textAnchor="middle"
					fontSize={info.fontSize}
					fontFamily={info.fontFamily}
					fontWeight={info.fontWeight || FontWeight.Normal}
					fill="black"
				>
					{info.text}
				</text>
			)
			y += info.height + textSpacing
		}
		return elements
	}

	render(onClick: (nodeId: string) => void): JSX.Element {
		return (
			<g className="box" cursor="pointer" key={this.id} onClick={() => onClick(this.target)} id={this.id}>
				<rect
					x={this.x}
					y={this.y}
					width={this.width}
					height={this.height}
					style={{ fill: '#E8E8E8', strokeWidth: 0, stroke: 'rgb(0,0,0)' }}
				/>
				{this.renderTextToBeDisplayed()}
			</g>
		)
	}
}

export default ShareGroup
