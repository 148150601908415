import { notification } from 'antd'
import axios from 'axios'
import { action, Action, State, thunk, Thunk } from 'easy-peasy'
import { APIPaths, AppRoutes, getRoute } from '../../AppRoutes'
import { AuthResponse, RedoxUser, SignupRequest } from '../../DTO/BaseApiDTO'
import ErrorMessages from '../../DTO/ErrorMessages'
import polyglot from '../../Translator'
import history from '../../Utils/history'

export interface IUserModel extends IUserModelActions {
	user: RedoxUser
	loading: boolean
	error: string
}

export interface ILoginInfos {
	email: string
	password: string
}

export interface IUserModelActions {
	logIn: Thunk<IUserModel, ILoginInfos>
	logInBegin: Action<IUserModel>
	logInError: Action<IUserModel, string>
	logInSuccess: Action<IUserModel, RedoxUser>

	signUp: Thunk<IUserModel, SignupRequest>
	signUpBegin: Action<IUserModel>
	signUpError: Action<IUserModel, string>
	signUpSuccess: Action<IUserModel, RedoxUser>

	signOut: Thunk<IUserModel>
	signOutBegin: Action<IUserModel>
	signOutError: Action<IUserModel, string>
	signOutSuccess: Action<IUserModel>

	getUser: Thunk<IUserModel>
	getUserBegin: Action<IUserModel>
	getUserError: Action<IUserModel, string>
	getUserSuccess: Action<IUserModel, RedoxUser>
}

export type IUserState = State<IUserModel>

export const userModel: IUserModel = {
	user: null,
	error: null,
	loading: false,

	logIn: thunk(async (actions, payload, { getState }) => {
		try {
			actions.logInBegin()
			const { data } = await axios.post<AuthResponse>(APIPaths.POST_LOG_IN, payload)
			actions.logInSuccess(data.user)
		} catch (error) {
			if (error.response.status === 401) {
				if (error.response.data.error === ErrorMessages.INVALID_PASSWORD) {
					notification.error({ placement: 'bottomRight', message: polyglot.t('invalidPassword') })
				} else if (error.response.data.error === ErrorMessages.USER_NOT_FOUND) {
					notification.error({ placement: 'bottomRight', message: polyglot.t('userNotFound') })
				}
			} else return actions.logInError(error.toString())
		}
	}),

	logInBegin: action((state, payload) => {
		state.loading = true
		state.error = null
	}),

	logInError: action((state, payload) => {
		state.loading = false
		state.error = payload
	}),

	logInSuccess: action((state, payload) => {
		state.loading = false
		state.user = payload
	}),

	signUp: thunk(async (actions, payload) => {
		try {
			actions.signUpBegin()
			const { data } = await axios.post<AuthResponse>(APIPaths.POST_SIGN_UP, payload)
			actions.signUpSuccess(data.user)
		} catch (error) {
			if (error.response.status === 401) {
				if (error.response.data.error === ErrorMessages.USER_ALREADY_EXISTS) {
					notification.error({ placement: 'bottomRight', message: polyglot.t('userAlreadyExist') })
				}
			} else return actions.signUpError(error.toString())
		}
	}),

	signUpBegin: action((state, payload) => {
		state.loading = true
		state.error = null
	}),

	signUpError: action((state, payload) => {
		state.loading = false
		state.error = payload
	}),

	signUpSuccess: action((state, payload) => {
		state.loading = false
		state.user = payload
	}),

	signOut: thunk(async (actions) => {
		try {
			actions.signOutBegin()
			await axios.post<void>(APIPaths.POST_SIGN_OUT)
			history.push(getRoute(AppRoutes.HOME))
			actions.signOutSuccess()
		} catch (error) {
			actions.signOutError(error.toString())
		}
	}),

	signOutBegin: action((state, payload) => {
		state.loading = true
		state.error = null
	}),

	signOutError: action((state, payload) => {
		state.loading = false
		state.error = payload
	}),

	signOutSuccess: action((state, payload) => {
		state.loading = false
		state.error = null
		state.user = null
	}),

	getUser: thunk(async (actions) => {
		try {
			actions.getUserBegin()
			const { data } = await axios.get<AuthResponse>(APIPaths.GET_USER_INFOS)
			actions.getUserSuccess(data.user)
		} catch (error) {
			if (error.response && error.response.status === 401) {
				actions.getUserSuccess(null)
			} else {
				actions.getUserError(error.toString())
			}
		}
	}),

	getUserBegin: action((state, payload) => {
		state.loading = true
		state.error = null
	}),

	getUserError: action((state, payload) => {
		state.loading = false
		state.user = null
		state.error = payload
	}),

	getUserSuccess: action((state, payload) => {
		state.loading = false
		state.error = null
		state.user = payload
	})
}
