import { action, Action, State, Thunk, thunk } from 'easy-peasy'
import languageManager, { Language } from '../../LanguageManager'

export interface ILanguageModel extends ILanguageModelActions {
  language: Language
}

export interface ILanguageModelActions {
  setLanguage: Action<ILanguageModel, Language>
  switchLanguage: Thunk<ILanguageModel>
}

export type ILanguageState = State<ILanguageModel>

export const languageModel: ILanguageModel = {
  language: languageManager.getUserLanguage(),

  setLanguage: action((state, payload) => {
    state.language = payload
    languageManager.setUserLanguage(state.language)
  }),

  switchLanguage: thunk(actions => {
    actions.setLanguage(languageManager.getOppositeLanguage())
  })
}
