import languageManager from './LanguageManager';

export enum AppRoutes {
	HOME = '',
	ABOUT = 'about',
	PRICING = 'pricing',
	MY_DIAGRAMS = 'my-diagrams',
	LAW_FIRM = 'law-firm',
	LAW_FIRM_INVITE = 'lawfirm-invite/:inviteId',
	FILL_DIAGRAMS = 'fill-diagrams',
	FILL_NEW_DIAGRAM = 'fill-diagrams/new',
	EMAIL_VALIDATION = 'email-validation/:validationId',
	VALIDATION_SUCCESS = 'validation-success',
	VALIDATION_REQUIRED = 'validation-required',
	TERMS = 'terms',
	PRIVACY = 'privacy',
	CONTACT = 'contact-us',
	LOGIN = 'login',
	SIGNUP = 'sign-up',
	TUTORIALS = 'tutorials',
	UNALLOWED = 'unallowed',
	DUPLICATE_SESSIONS = 'duplicate-sessions',
	PASSWORD_RESET = 'password-reset/:resetId'
}

export enum APIPaths {
	POST_SIGN_IN = '/user-signed-in',
	POST_SIGN_UP = '/sign-up',
	POST_SIGN_OUT = '/sign-out',
	POST_LOG_IN = '/sign-in',
	GET_DIAGRAMS_ALLOWED = '',
	GET_ALL_DIAGRAMS = '/diagrams',
	ELK = '/elk',
	GET_DIAGRAM = '/diagrams',
	GET_DIAGRAMS_AUTHORIZE = '/diagrams/allowed',
	POST_DIAGRAM = '/diagrams',
	PUT_DIAGRAM = '',
	DEL_DIAGRAM = '/diagrams/',
	PDF_DOWNLOAD = '/diagrams/pdf',
	VALIDATE_EMAIL = '/validate-email',
	GET_USER_INFOS = '/user',
	RESET_PASSWORD = '/reset-password',
	UPDATE_PASSWORD = '/update-password',
	LAWFIRM_INVITE = '/lawfirm-invite',
	ACCEPT_INVITE = '/accept-invite',
	DECLINE_INVITE = '/decline-invite'
}

export const getRoute = (route: string) => {
	if (languageManager.getStringUrlLanguage(route)) {
		return route;
	} else {
		return `/${languageManager.getUserLanguage()}/${route}`;
	}
};
