import './App.scss'
import './Common.scss'
import './Helpers.scss'
import './Style.scss'
import 'animate.css/animate.min.css'
import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'loaders.css'
import 'normalize.css'

import { Dispatch } from 'easy-peasy'
import React, { Component, lazy, Suspense, useEffect } from 'react'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'
import { RouteChildrenProps, Switch, withRouter } from 'react-router'
import { Redirect, Route as ReactRoute } from 'react-router-dom'
import shortid from 'shortid'
import styled from 'styled-components'

import { AppRoutes, getRoute } from './AppRoutes'
import IEFallback from './Base/Home/IEFallback'
import config from './config'
import { configureAxios } from './configureAxios'
import LoadingDots from './Diagrams/LoadingDots'
import { isBrowserSupported } from './Diagrams/utils/utils'
import { RedoxUser } from './DTO/BaseApiDTO'
import languageManager from './LanguageManager'
import { IApplicationModel, IApplicationState } from './store/store'
import polyglot from './Translator'
import { getCookie, setCookie } from './Utils/cookies'
import history from './Utils/history'
import WaitingComponent from './WaitingComponent'

const NotificationSystem = lazy(() => import('react-notification-system'))
const ErrorBoundary = WaitingComponent(lazy(() => import('./Components/ErrorBoundary')))

const About = WaitingComponent(lazy(() => import('./Base/About' /* webpackChunkName: "About" */)))
const ContactUs = WaitingComponent(lazy(() => import('./Base/ContactUs' /* webpackChunkName: "ContactUs" */)))
const Footer = WaitingComponent(lazy(() => import('./Base/Footer' /* webpackChunkName: "Footer" */)))
const Home = WaitingComponent(lazy(() => import('./Base/Home/Home' /* webpackChunkName: "Home" */)))
const LawFirm = WaitingComponent(lazy(() => import('./Base/LawFirm/LawFirm' /* webpackChunkName: "LawFirm" */)))
const LawFirmInvite = WaitingComponent(
	lazy(() => import('./Base/LawFirm/Invite/LawFirmInvite' /* webpackChunkName: "LawFirmInvite" */))
)
const Login = WaitingComponent(lazy(() => import('./Auth/Login' /* webpackChunkName: "Login" */)))
const PasswordReset = WaitingComponent(
	lazy(() => import('./Auth/PasswordReset' /* webpackChunkName: "PasswordReset" */))
)

const Signup = WaitingComponent(lazy(() => import('./Auth/Signup' /* webpackChunkName: "Signup" */)))
const Navbar = WaitingComponent(lazy(() => import('./Base/Navbar' /* webpackChunkName: "Navbar" */)))
const NotFound = WaitingComponent(lazy(() => import('./Base/NotFound' /* webpackChunkName: "NotFound" */)))
const Pricing = WaitingComponent(lazy(() => import('./Base/Pricing/Pricing' /* webpackChunkName: "Pricing" */)))
const Privacy = WaitingComponent(lazy(() => import('./Base/Privacy' /* webpackChunkName: "Privacy" */)))
const Terms = WaitingComponent(lazy(() => import('./Base/Terms' /* webpackChunkName: "Terms" */)))
const MyDiagrams = WaitingComponent(lazy(() => import('./Diagrams/MyDiagrams' /* webpackChunkName: "MyDiagrams" */)))
const Tutorials = WaitingComponent(
	lazy(() => import('./Base/Tutorials/TutorialsIndex' /* webpackChunkName: "Tutorials" */))
)
const Graph = WaitingComponent(lazy(() => import('./Diagrams/Graph' /* webpackChunkName: "Graph" */)))
const DiagramsUnallowed = WaitingComponent(
	lazy(() => import('./Diagrams/Unallowed/DiagramsUnallowed' /* webpackChunkName: "About" */))
)
const EmailValidation = WaitingComponent(
	lazy(() => import('./Base/EmailValidation/EmailValidation' /* webpackChunkName : "EmailValidation" */))
)
const EmailVerificationRequired = WaitingComponent(
	lazy(() =>
		import('./Base/EmailValidation/EmailVerificationRequired' /* webpackChunkName : "EmailVerificationRequired" */)
	)
)
const ValidationSuccess = WaitingComponent(
	lazy(() => import('./Base/EmailValidation/ValidationSuccess' /* webpackChunkName : "ValidationSuccess" */))
)
const DuplicateSessions = WaitingComponent(
	lazy(() => import('./Base/DuplicateSessions' /* webpackChunkName : "DuplicateSessions" */))
)

const initGA = () => {
	ReactGA.initialize(config.GOOGLE_ANALYTICS_ID)
}
const logPageView = () => {
	ReactGA.set({ page: window.location.pathname })
	ReactGA.pageview(window.location.pathname)
}
interface MaincontentProps {
	pathname: string
}
const Maincontent = styled.div`
	background-color: #ffffff;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	flex: 1 0 auto;
	position: relative;
	margin-top: 65px;
	margin-top: ${(props: MaincontentProps) =>
		props.pathname.includes('/fill-diagrams') ? '15px' : '66px' /* Size of the navbar + 1px border */};
`

const AppView = (props: IProps & RouteChildrenProps) => {
	let notificationSystem: any

	useEffect(() => {
		initGA()
		configureAxios()
		configureSessionId()
		ensureLangInUrl()
		props.getUser()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(
		() => {
			logPageView()
			ensureLangInUrl()
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[ props.location.pathname ]
	)

	const ensureLangInUrl = () => {
		if (!languageManager.getUrlLanguage()) {
			history.push(getRoute(props.history.location.pathname.replace('/', '')))
		}
	}

	const configureSessionId = () => {
		if (!getCookie('sessionId')) {
			setCookie('sessionId', shortid.generate())
		}
	}

	const addNotification = (type, message, autoDismiss) => {
		const notification = {
			title: polyglot.t(type),
			level: type,
			position: 'br',
			autoDismiss,
			action: null,
			children: null
		}
		if (typeof message === 'string') {
			notification.action = {
				label: 'OK'
			}
		} else {
			notification.children = message
		}
		notificationSystem.addNotification(notification)
	}

	const addNotificationSuccess = (message) => {
		addNotification('success', message, 7)
	}

	const addNotificationWarning = (message) => {
		addNotification('warning', message, 10)
	}

	const addNotificationError = (message, support = true) => {
		if (support) {
			message = (
				<span>
					{message} {polyglot.t('teamNotified')} {polyglot.t('fillOutReportP1')}{' '}
					<a href='/contact-us?type=bug'>{polyglot.t('fillOutReportP2')}</a>
					{'.'}
				</span>
			)
		} else {
			message = <span>{message}</span>
		}
		addNotification('error', message, 0)
	}

	const notifications = {
		addNotificationSuccess: addNotificationSuccess,
		addNotificationWarning: addNotificationWarning,
		addNotificationError: addNotificationError
	}

	const notificationSystemRef = (notifSystem) => {
		notificationSystem = notifSystem
	}

	return isBrowserSupported() ? (
		<Suspense fallback={<LoadingDots />}>
			<div id='app'>
				<ScrollToTop />
				<NotificationSystem ref={notificationSystemRef} />
				<Navbar location={props.location} />
				<Maincontent pathname={props.location.pathname}>
					<Switch>
						<ReactRoute
							path='/:lang'
							render={({ match }) => (
								<Switch>
									<Route exact path={`${match.url}`} component={Home} />
									<Route exact path={`${match.url}/${AppRoutes.ABOUT}`} component={About} />
									<Route exact path={`${match.url}/${AppRoutes.PRICING}`} component={Pricing} />
									<Route exact path={`${match.url}/${AppRoutes.TERMS}`} component={Terms} />
									<Route exact path={`${match.url}/${AppRoutes.TUTORIALS}`} component={Tutorials} />
									<Route exact path={`${match.url}/${AppRoutes.PRIVACY}`} component={Privacy} />
									<Route
										exact
										path={`${match.url}/${AppRoutes.CONTACT}`}
										noContactUs
										component={ContactUs}
										componentProps={{ notifications: notifications }}
									/>
									<Route
										exact
										path={`${match.url}/${AppRoutes.VALIDATION_SUCCESS}`}
										component={ValidationSuccess}
									/>
									<Route
										exact
										path={`${match.url}/${AppRoutes.VALIDATION_REQUIRED}`}
										component={EmailVerificationRequired}
									/>
									<Route
										exact
										path={`${match.url}/${AppRoutes.EMAIL_VALIDATION}`}
										component={EmailValidation}
									/>
									<Route
										exact
										path={`${match.url}/${AppRoutes.LOGIN}`}
										component={() => <Login {...props} />}
									/>
									<Route
										exact
										path={`${match.url}/${AppRoutes.PASSWORD_RESET}`}
										component={() => <PasswordReset {...props} />}
									/>
									<Route
										exact
										path={`${match.url}/${AppRoutes.SIGNUP}`}
										component={() => <Signup {...props} />}
									/>
									<Route
										exact
										path={`${match.url}/${AppRoutes.UNALLOWED}`}
										component={() => <DiagramsUnallowed />}
									/>
									<Route
										exact
										path={`${match.url}/${AppRoutes.DUPLICATE_SESSIONS}`}
										component={DuplicateSessions}
									/>
									<PrivateRoute
										userSignedIn={!!props.user}
										exact
										path={`${match.url}/${AppRoutes.MY_DIAGRAMS}`}
										component={MyDiagrams}
										componentProps={{
											notifications: notifications
										}}
									/>

									<PrivateRoute
										userSignedIn={!!props.user}
										exact
										path={`${match.url}/${AppRoutes.LAW_FIRM}`}
										component={LawFirm}
										componentProps={{
											notifications: notifications
										}}
									/>
									<Route
										exact
										path={`${match.url}/${AppRoutes.LAW_FIRM_INVITE}`}
										component={LawFirmInvite}
										componentProps={{
											notifications: notifications
										}}
									/>

									<PrivateRoute
										path={`${match.url}/${AppRoutes.FILL_DIAGRAMS}/:diagramId`}
										userSignedIn={!!props.user}
										component={Graph}
										componentProps={{ notifications: notifications }}
									/>

									<ReactRoute component={NotFound} />
								</Switch>
							)}
						/>
						<Route component={NotFound} />
					</Switch>
				</Maincontent>
				<Footer location={props.location} />
			</div>
		</Suspense>
	) : (
		<IEFallback />
	)
}

interface IStateProps {
	user: RedoxUser
	language: string
}

interface IDispatchProps {
	getUser: () => void
}
type IProps = IStateProps & IDispatchProps

const mapDispatchToProps = (dispatch: Dispatch<IApplicationModel>): IDispatchProps => ({
	getUser: () => dispatch.user.getUser()
})

const mapStateToProps = (state: IApplicationState): IStateProps => {
	return {
		user: state.user.user,
		language: state.language.language
	}
}

const App = connect(mapStateToProps, mapDispatchToProps)(AppView)

export default withRouter(App)

const ScrollToTop = withRouter(
	class ScrollToTopWithoutRouter extends Component<RouteChildrenProps> {
		componentDidUpdate(prevProps) {
			if (this.props.location !== prevProps.location) {
				window.scrollTo(0, 0)
			}
		}
		render() {
			return null
		}
	}
)

const Route = ({ component: Component, componentProps = null, noContactUs = true, ...rest }) => (
	<ReactRoute
		{...rest}
		render={(props) => (
			<ErrorBoundary contactUs={!noContactUs}>
				<Component {...props} {...componentProps} />
			</ErrorBoundary>
		)}
	/>
)

const PrivateRoute = ({ userSignedIn, component, ...rest }) =>
	userSignedIn ? (
		<Route component={component} {...rest} />
	) : (
		<ReactRoute
			render={(props) => (
				<Redirect to={{ pathname: getRoute(AppRoutes.LOGIN), state: { from: props.location.pathname } }} />
			)}
		/>
	)
