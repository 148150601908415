import { immerable } from 'immer'
import { generate as randomstring } from 'shortid'
import { IShare, ShareRights, ShareType } from '../DiagramApiDTO'

export class Share implements IShare {
	id: string = randomstring()
	certificateNumber: string
	amount: number
	percentage: number
	type: ShareType
	category: string
	participating: ShareRights = ShareRights.NA
	voting: ShareRights = ShareRights.NA
	dividend: ShareRights = ShareRights.NA
	pbr: number
	cv: number
	vr: number
	jvm: number
	other: string;
	[immerable] = true

	constructor(args: Partial<IShare> = {}) {
		this.id = args.id || this.id
		this.certificateNumber = args.certificateNumber || this.certificateNumber
		this.amount = args.amount || this.amount
		this.percentage = args.percentage || this.percentage
		this.type = args.type || this.type
		this.category = args.category || this.category
		this.participating = args.participating || this.participating
		this.voting = args.voting || this.voting
		this.dividend = args.dividend || this.dividend
		this.pbr = args.pbr || this.pbr
		this.cv = args.cv || this.cv
		this.vr = args.vr || this.vr
		this.jvm = args.jvm || this.jvm
		this.other = args.other || this.other
	}
}

export default Share
