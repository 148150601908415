import moment from 'moment'
import * as React from 'react'
import { IAdministrators, ICorporation, PersonTypes, DiagramLanguage } from '../DiagramApiDTO'
import Person from './Person'
import { ITextRequest, textDimensions, ITextWithDimensions, FontWeight } from '../textDimensions'
import { translate, roleText, wrapText } from '../utils'

const translations = {
	and: {
		en: 'and',
		fr: 'et'
	},
	admin: {
		en: 'Administrators',
		fr: 'Administrateurs'
	}
}

export class Corporation extends Person implements ICorporation {
	type = PersonTypes.CORPORATION
	neq: string
	constitutionDate: string
	constitutionLaw: string
	financialYear: string
	admins: IAdministrators[] = []

	constructor(args: Partial<ICorporation> = {}) {
		super(args)
		this.neq = args.neq || this.neq
		this.constitutionDate = args.constitutionDate || this.constitutionDate
		this.constitutionLaw = args.constitutionLaw || this.constitutionLaw
		this.financialYear = args.financialYear || this.financialYear
		this.admins = args.admins || this.admins
	}

	getInfosToBeDisplayed(): ITextWithDimensions[] {
		const diagram = this.diagram
		const fontSize = diagram.fontSize
		const smallFont = Math.round(fontSize * 0.7)
		const fontFamily = diagram.fontFamily

		const date = (cd) => {
			let d
			if (cd) {
				if (diagram.language === DiagramLanguage.FR) {
					d = `DC: ${moment(cd).format('MM/DD/YYYY')}`
				} else {
					d = `CD: ${moment(cd).format('MM/YYYY')}`
				}
			}
			return d
		}
		const fyDate = (cd) => {
			let d
			if (cd) {
				if (diagram.language === DiagramLanguage.FR) {
					d = `FA: ${moment(this.financialYear).format('DD/MM')}`
				} else {
					d = `FYE: ${moment(this.financialYear).format('DD/MM')}`
				}
			}
			return d
		}

		const law = this.constitutionLaw
		const neq = this.neq && `NEQ: ${this.neq}`

		const administrators = this.admins.map((item, index) => {
			const roleString = item.roles.map((el, index) => {
				if (item.roles.length - 1 === index) {
					return `${roleText(el, this.diagram.getLanguage())}`
				} else if (item.roles.length - 2 === index) {
					return `${roleText(el, this.diagram.getLanguage())} ${translate(translations.and, this.diagram.getLanguage())} `
				} else return `${roleText(el, this.diagram.getLanguage())}, `
			})

			if (index === 0 && roleString.join('').length) {
				return `${translate(translations.admin, this.diagram.getLanguage())}: ${item.name} (${roleString.join('')})`
			} else if (index === 0 && !roleString.join('').length) {
				return `${translate(translations.admin, this.diagram.getLanguage())}: ${item.name}`
			} else if (!roleString.join('').length) {
				return `${item.name} `
			} else {
				return `${item.name} (${roleString.join('')})`
			}
		})

		const infos: ITextRequest[] = [
			...wrapText(this.name, 25).map((t) => ({
				text: t,
				fontSize: fontSize,
				fontFamily: fontFamily,
				fontWeight: FontWeight.Bold
			})),

			{ text: neq, fontSize: smallFont, fontFamily: fontFamily },
			{ text: date(this.constitutionDate), fontSize: smallFont, fontFamily: fontFamily },
			{ text: law, fontSize: smallFont, fontFamily: fontFamily },
			{ text: fyDate(this.financialYear), fontSize: smallFont, fontFamily: fontFamily },
			...administrators.map((admin) => ({ text: admin, fontSize: smallFont, fontFamily: fontFamily }))
		]

		return infos.filter((el) => el.text && el.text.length).map((info) => textDimensions(info))
	}

	computeSelfDimensions() {
		const dims = this.getContentDimensions()
		this.width = dims.width
		this.height = dims.height
	}

	render(nodeClick: (nodeId: string) => void, selectedNodeId: string): JSX.Element {
		const { shapesBorderWidth } = this.diagram.getLayoutProperties()
		const bgColor: string = selectedNodeId === this.id ? Person.SelectedColor : this.bgColor
		return (
			<g
				className="box"
				key={this.id}
				onClick={(e) => {
					e.stopPropagation()
					nodeClick(this.id)
				}}
				id={this.id}
			>
				<rect
					x={this.x}
					y={this.y}
					width={this.width}
					height={this.height}
					style={{ fill: bgColor, strokeWidth: shapesBorderWidth, stroke: 'black' }}
				/>
				{this.renderTextToBeDisplayed()}
			</g>
		)
	}
}

export default Corporation
