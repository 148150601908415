import { merge } from 'lodash'
import { IPerson, PersonTypes } from '../DiagramApiDTO'
import Corporation from './Corporation'
import Diagram from './Diagram'
import Partnership from './Partnership'
import Person from './Person'
import PhysicalPerson from './PhysicalPerson'
import Trust from './Trust'

export const personFactory = (orphan: IPerson): Person => {
	if (orphan.type === PersonTypes.CORPORATION) {
		return new Corporation(orphan)
	} else if (orphan.type === PersonTypes.PERSON) {
		return new PhysicalPerson(orphan)
	} else if (orphan.type === PersonTypes.TRUST) {
		return new Trust(orphan)
	} else if (orphan.type === PersonTypes.PARTNERSHIP) {
		return new Partnership(orphan)
	} else console.error(`error: type not found for ${orphan}`)
}
