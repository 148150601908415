import Logger from './Components/Logger'
import languageManager from './LanguageManager'
import { AppTranslations } from './Translations'

class Translator {
  dict = AppTranslations

  t = (key, objectToReplace = null) => {
    if (this.isString(key)) {
      if (!objectToReplace) {
        return this.dict[key][languageManager.getUserLanguage()] || key
      } else {
        return (
          this.dict[key][languageManager.getUserLanguage()].replace(/%{(\w+)}/g, (_, k) => {
            return objectToReplace[k]
          }) || key
        )
      }
    } else {
      Logger.error('the following key is not a string: ', key)
    }
  }

  tr = (key: { en: string | JSX.Element; fr: string | JSX.Element }): any => {
    return key[languageManager.getUserLanguage()]
  }

  // Returns if a value is a string
  isString = value => {
    return typeof value === 'string' || value instanceof String
  }
}

export const polyglot = new Translator()

export default polyglot
