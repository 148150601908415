export const graphTranslations = {
	link: {
		en:
			'Link all charts, so when you change a property of a share and/or entity, all same shares/entity will be updated across other charts.',
		fr:
			"Lier toutes les diagrammes, ainsi lors d'un changement de valeur d'une action et/ou d'entité, toutes les actions/entitées identiques seront mise à jour dans les autrres diagrammes."
	},
	goBack: {
		en: 'Go Back',
		fr: 'Retour'
	},
	physicalPerson: {
		en: 'Person',
		fr: 'Personne'
	},
	switchBrowser: {
		en: 'To view our full website, open www.redox-technologies.ca using Chrome, Safari, Firefox or Edge.',
		fr:
			'Pour consulter la version complète de notre site web, veuillez ouvrir www.redox-technologies.ca avec Chrome, Safari, Firefox ou Edge.'
	},
	corporationsHeight: {
		en: 'Corporations Height',
		fr: 'Hauteur corporations'
	},
	shareMinimum: {
		en: 'There must be at least one share.',
		fr: 'Il doit y avoir au minimum une action.'
	},
	fontSize: {
		en: 'Font size',
		fr: 'Taille police'
	},
	display: {
		en: 'Display',
		fr: 'Affichage'
	},
	shuffle: {
		en: 'Shuffle nodes',
		fr: 'Réorganiser'
	},

	verticalAlign: {
		en: 'Alignment',
		fr: 'Alignement'
	},
	notSupportedP1: {
		en: 'Please, note that Redox no longer supports Internet Explorer.',
		fr: 'SVP, notez que Redox ne supporte plus Internet Explorer.'
	},
	notSupportedP2: {
		en: 'We recommend upgrading to the latest Google Chrome or Firefox.',
		fr: "Nous recommendons d'utiliser la dernière version de Google Chrome ou Firefox."
	},
	accessAllContentP1: {
		en: 'In order to access Redox full content, please come back to our website using Google Chrome or Safari.',
		fr: 'Afin de pouvoir accèder à tout le contenu, veuillez accèder à notre site via Google Chrome ou Firefox.'
	},
	trust: {
		en: 'Trust',
		fr: 'Fiducie'
	},
	centerAlignment: {
		en: 'Align center',
		fr: 'Aligné au centre'
	},
	leftAlignment: {
		en: 'Align left',
		fr: 'Aligner à gauche'
	},
	rightAlignment: {
		en: 'Align right',
		fr: 'Aligner à droite'
	},
	goBackToMyDiagrams: {
		en: 'Go back to my charts',
		fr: 'Retour à mes organigrammes'
	},
	warning: {
		en: 'Warning',
		fr: 'Attention'
	},
	yes: {
		en: 'Yes',
		fr: 'Oui'
	},
	no: {
		en: 'No',
		fr: 'Non'
	},
	sureToDelete: {
		en: 'Are you sure to delete this project?',
		fr: 'Vous êtes certain de vouloir supprimer ce projet?'
	},
	sureToDeleteShares: {
		en: 'Are you sure to delete those shares?',
		fr: 'Vous êtes certain de vouloir supprimer ces actions?'
	},
	add: {
		en: 'Add',
		fr: 'Ajouter'
	},
	browserWarning: {
		en:
			'You are currently using a browser not supported by Redox (i.e. Internet Explorer). In order to use Redox, please try again with a different browser such as: Google Chrome, Safari, Firefox or Edge.',
		fr:
			"Vous tenter d'utiliser Redox sous un navigateur non supporté (p. ex. Internet Explorer). Afin d'utiliser Redox, veuillez ré-essayer avec un autre navigateur, tel que: Google Chrome, Safari, Firefox ou Edge."
	},
	certificateNumber: {
		en: 'Certificate #',
		fr: '# Certificat'
	},
	newProject: {
		en: 'New Project',
		fr: 'Nouveau Projet'
	},
	diagram1: {
		en: 'Org Chart 1',
		fr: 'Organigramme 1'
	},
	import: {
		en: 'Import',
		fr: 'Import'
	},
	partnership: {
		en: 'Partnership',
		fr: 'Société de personnes'
	},

	select: {
		en: 'Select',
		fr: 'Sélectionner'
	},
	copy: {
		en: 'Copy',
		fr: 'Copier'
	},
	newAdmin: {
		en: 'New administrator',
		fr: 'Nouvel administrateur'
	},
	settings: {
		en: 'Settings',
		fr: 'Réglages'
	},
	ordinary: {
		en: 'Ordinary',
		fr: 'Ordinaire'
	},
	privileged: {
		en: 'Privileged',
		fr: 'Privilégiée'
	},
	control: {
		en: 'Control',
		fr: 'Contrôle'
	},
	rollover: {
		en: 'Rollover',
		fr: 'Roulement'
	},
	admin: {
		en: 'Administrators',
		fr: 'Administrateurs'
	},
	administrator: {
		en: 'Administrator',
		fr: 'Administrateur'
	},
	president: {
		en: 'President',
		fr: 'Président'
	},
	vicePresident: {
		en: 'Vice-president',
		fr: 'Vice-président'
	},
	tresorer: {
		en: 'Tresorer',
		fr: 'Trésorier'
	},
	secretary: {
		en: 'Secretary',
		fr: 'Sécrétaire'
	},
	shares: {
		en: 'Shares',
		fr: 'Actions'
	},
	sharesNoCaps: {
		en: 'shares',
		fr: 'actions'
	},
	part: {
		en: 'Share',
		fr: 'Part'
	},
	textVersion: {
		en: 'Text version',
		fr: 'Version textuelle'
	},
	download: {
		en: 'Download',
		fr: 'Télécharger'
	},
	sureToCancel: {
		en: 'Sure to cancel?',
		fr: 'Êtes-vous sur de vouloir annuler?'
	},
	save: {
		en: 'Save',
		fr: 'Sauvegarder'
	},
	exit: {
		en: 'Exit',
		fr: 'Quitter'
	},
	center: {
		en: 'Center',
		fr: 'Centrer'
	},

	new: {
		en: 'New',
		fr: 'Nouveau'
	},
	newOrgChart: {
		en: 'New Org Chart',
		fr: 'Nouvel Organigramme'
	},
	name: {
		en: 'Name',
		fr: 'Nom'
	},
	participations: {
		en: 'Participations',
		fr: 'Participations'
	},
	constitutionDate: {
		en: 'Constitution date',
		fr: 'Date de constitution'
	},
	endFiscalYear: {
		en: 'Fiscal year end',
		fr: "Fin d'année fiscale"
	},
	constitutiveLaw: {
		en: 'Constitutive law',
		fr: 'Loi constitutive'
	},
	myFirm: {
		en: 'My Firm',
		fr: 'Mon Cabinet'
	},
	firm: {
		en: 'Firm',
		fr: 'Cabinet'
	},
	myCharts: {
		en: 'My charts',
		fr: 'Mes organigrammes'
	},
	charts: {
		en: 'Charts',
		fr: 'Graphiques'
	},

	createNewAccount: {
		en: 'Create your account',
		fr: 'Créez votre compte'
	},
	logIn: {
		en: 'Log in',
		fr: 'Connexion'
	},

	enter: {
		en: 'Enter',
		fr: 'Entrer'
	},

	verticalPosition: {
		en: 'Vertical position',
		fr: 'Position verticale'
	},
	backgroundColor: {
		en: 'Background',
		fr: 'Arrière plan'
	},
	delete: {
		en: 'Delete',
		fr: 'Supprimer'
	},
	width: {
		en: 'Width',
		fr: 'Largeur'
	},
	amount: {
		en: 'Amount',
		fr: 'Nombre'
	},
	percentage: {
		en: 'Percentage (%)',
		fr: 'Pourcentage (%)'
	},
	category: {
		en: 'Category',
		fr: 'Category'
	},
	participating: {
		en: 'Participating',
		fr: 'Participante'
	},
	voting: {
		en: 'Voting',
		fr: 'Votante'
	},
	dividend: {
		en: 'Dividend',
		fr: 'Dividende'
	},
	acb: {
		en: 'ACB ($)',
		fr: 'PBR ($)'
	},
	puc: {
		en: 'PUC ($)',
		fr: 'CV ($)'
	},
	fmv: {
		en: 'FMV ($)',
		fr: 'JVM ($)'
	},
	cv: {
		en: 'RV ($)',
		fr: 'VR ($)'
	},
	title: {
		en: 'Title',
		fr: 'Titre'
	},
	horizontalSpacing: {
		en: 'Horizontal spacing',
		fr: 'Espacement horizontal'
	},
	verticalSpacing: {
		en: 'Vertical spacing',
		fr: 'Espacement vertical'
	},
	horizontalPadding: {
		en: 'Horizontal padding',
		fr: 'Remplissage horizontal'
	},
	verticalPadding: {
		en: 'Vertical padding',
		fr: 'Remplissage vertical'
	},
	other: {
		en: 'Other',
		fr: 'Autre'
	},
	showLegend: {
		en: 'Show legend',
		fr: 'Afficher la légende'
	},
	legend: {
		en: 'Legend',
		fr: 'Légende'
	},
	language: {
		en: 'Language',
		fr: 'Langue'
	},
	fontColor: {
		en: 'Font color',
		fr: 'Couleur du texte'
	},
	titleSize: {
		en: 'Size',
		fr: 'Taille'
	},
	confidentialMode: {
		en: 'Shorten share description',
		fr: 'Abréger la description des actions'
	},
	importChart: {
		en: 'Import a chart',
		fr: 'Importer un organigramme'
	},
	confidentialModeInfos: {
		en: 'Hides all data relating to share values.',
		fr: 'Masque toutes les données relatives à la valeur des actions.'
	},
	showHide: {
		en: 'Show / Hide',
		fr: 'Afficher / Masquer'
	},
	defaultOrgChart: {
		en: 'Org chart #1',
		fr: 'Organigramme #1'
	},
	inProgress: {
		en: 'In progress',
		fr: 'En cours'
	},
	done: {
		en: 'Done',
		fr: 'Terminé'
	},
	myProjects: {
		en: 'My Projects',
		fr: 'Mes Projets'
	},
	titlePosition: {
		en: 'Position',
		fr: 'Position'
	},
	enterProjectName: {
		en: 'Please enter the project name: ',
		fr: 'Veuillez entrer le nom du projet:'
	},
	diagramName: {
		en: 'Org chart name',
		fr: "Nom de l'organigramme"
	},
	graphSaving: {
		en: 'Saving the current chart',
		fr: 'Sauvegarde du graphique en cours'
	},
	allSaved: {
		en: 'All changes saved in Redox',
		fr: 'Toutes les modifications sauvegardées dans Redox'
	},
	beneficiary: {
		en: 'Beneficiary',
		fr: 'Bénéficiaire'
	},
	isComposedAsFollowed: {
		en: 'is composed as followed:',
		fr: 'est composée comme suit:'
	},
	beneficiaries: {
		en: 'Beneficiaries',
		fr: 'Bénéficiaires'
	},
	shareholders: {
		en: 'Shareholders',
		fr: 'Actionnaires'
	},
	partners: {
		en: 'Partners',
		fr: 'Associés'
	},
	and: {
		en: 'and',
		fr: 'et'
	},
	fiduciary: {
		en: 'Fiduciary',
		fr: 'Fiduciaire'
	},
	fiduciaries: {
		en: 'Fiduciaries',
		fr: 'Fiduciaires'
	},
	sharesOwnBy: {
		en: 'Participations of',
		fr: 'Participations de'
	},
	in: {
		en: 'in',
		fr: 'dans'
	},
	of: {
		en: 'of',
		fr: 'de'
	},
	addLot: {
		en: 'Add lot',
		fr: 'Ajouter un lot'
	},
	importLot: {
		en: 'Import lot',
		fr: 'Importer un lot'
	},
	specifyNumberOfShares: {
		en: 'You must specify a number of shares.',
		fr: "Vous devez spécifier un nombre d'actions"
	},
	isHeld: {
		en: ' is held as follows:',
		fr: 'est détenue comme suit:'
	},
	editEdge: {
		en: 'Edge styling',
		fr: 'Style des liens'
	},
	edgeColor: {
		en: 'Edge color',
		fr: 'Couleur des liens'
	},
	oneColorPerEntity: {
		en: 'One different color per entity',
		fr: 'Une couleur différente par entité'
	},
	errorEncountered: {
		en: 'Error encountered',
		fr: 'Une erreur est survenue'
	}
}
