export interface IDiagramAllowedResponse {
  hasLawFirm: boolean
  authorized: boolean
}

export interface IDiagramProject {
  name: string
  createdAt: Date
  status: string
  diagrams: IDiagram[]
}

export interface IDiagram {
  id?: string
  name: string
  persons: IPerson[]

  fontFamily: string
  fontColor: string
  fontSize: number
  showTitle?: boolean
  showLegend: boolean

  confidentialMode: boolean

  title: {
    value: string
    size: number
  }

  footNotes: INote[]

  horizontalSpacing: number
  verticalSpacing: number

  sidePadding: number
  topPadding: number
  shapesBorderWidth: number
  language: DiagramLanguage
}

export enum DiagramLanguage {
  EN = 'EN',
  FR = 'FR',
}
export interface INote {
  id: string
  note: string
}

export enum PersonTypes {
  CORPORATION = 'Corporation',
  PERSON = 'Person',
  PARTNERSHIP = 'Partnership',
  TRUST = 'Trust',
}

export interface IPerson {
  id: string
  name: string
  type: PersonTypes
  shareGroups: IShareGroup[]
  edgeColor: string
  // styling
  bgColor: string
}

export interface ICorporation extends IPerson {
  neq: string
  constitutionDate: string //moment
  constitutionLaw: string
  financialYear: string //moment
  admins: IAdministrators[]
}

export interface IPhysicalPerson extends IPerson {}

export interface ITrust extends IPerson {
  fiduciaires: string[]
  showFiduciaires: boolean
}

export interface IPartnership extends IPerson {}

export enum ShareRights {
  NA = 'N/A',
  ON = 'ON',
  OFF = 'OFF',
}

export interface IShareGroup {
  target: string
  shares: IShare[]

  // styling
  dotted: boolean
  color: string
}

export interface IShare {
  id: string
  certificateNumber: string
  amount: number
  percentage: number
  type: ShareType
  category: string
  participating: ShareRights
  voting: ShareRights
  dividend: ShareRights
  pbr: number
  cv: number
  vr: number
  jvm: number
  other: string
}

export enum ShareType {
  ORDINARY = 'ORDINARY',
  PREFERRED = 'PREFERRED',
  CONTROL = 'CONTROL',
  ROLLOVER = 'ROLLOVER',
}

export enum AdminType {
  PRESIDENT = 'PRESIDENT',
  VICE_PRESIDENT = 'VICE_PRESIDENT',
  SECRETARY = 'SECRETARY',
  TRESORER = 'TRESORER',
  ADMIN = 'ADMIN',
}

export interface IAdministrators {
  id: string
  name: string
  roles: AdminType[]
}

export interface ILayoutProperties {
  topPadding: number
  sidePadding: number
  fontSize: number
  smallFont: number
  textSpacing: number
  fontFamily: string
  shapesBorderWidth: number
}
