type AppStage = 'production' | 'staging' | 'dev'

interface IAppConfig {
  API_ENDPOINT: string
  GOOGLE_ANALYTICS_ID: string
  ENV: AppStage
}
const GA_ID = 'UA-141727221-1'
const dev: IAppConfig = {
  API_ENDPOINT: `http://${window.location.hostname}:5000`,
  GOOGLE_ANALYTICS_ID: GA_ID,
  ENV: 'dev'
}

const staging: IAppConfig = {
  API_ENDPOINT: 'https://api.redox-staging.xyz',
  GOOGLE_ANALYTICS_ID: GA_ID,
  ENV: 'staging'
}

const prod: IAppConfig = {
  API_ENDPOINT: 'https://api.redox-technologies.ca',
  GOOGLE_ANALYTICS_ID: GA_ID,
  ENV: 'production'
}

let config: IAppConfig

console.log('APP stage : ', process.env.REACT_APP_STAGE)

switch (process.env.REACT_APP_STAGE) {
  case 'production':
    config = prod
    break

  case 'staging':
    config = staging
    break

  default:
    config = dev
    break
}

export default {
  ...config
}
