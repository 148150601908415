import { immerable } from 'immer'
import { maxBy, sumBy } from 'lodash'
import * as React from 'react'
import { generate, generate as randomstring } from 'shortid'
import { IGraphNode } from '../IGraphNode'
import { colors } from '../colors'
import { IPerson, PersonTypes } from '../DiagramApiDTO'
import { IDimensions, ITextWithDimensions, FontWeight } from '../textDimensions'
import Diagram from './Diagram'
import ShareGroup from './ShareGroup'
import { getHangingFontOffset } from '../utils'

abstract class Person implements IGraphNode, IPerson {
	y: number
	x: number
	width: number
	height: number
	id: string = randomstring()
	name: string = ''
	type: PersonTypes
	shareGroups: ShareGroup[] = []
	edgeColor: string = '#000000'
	bgColor: string = '#FFFFFF';
	[immerable] = true

	protected diagram: Diagram

	static readonly SelectedColor = '#2dd293'

	constructor(args: Partial<IPerson> = {}) {
		this.id = args.id || this.id
		this.name = args.name || this.name
		this.type = args.type || this.type
		this.shareGroups = args.shareGroups
			? args.shareGroups.map((group) => new ShareGroup(this.id, group))
			: this.shareGroups
		this.edgeColor = args.edgeColor || this.edgeColor
		this.bgColor = args.bgColor || this.bgColor
	}

	abstract computeSelfDimensions()
	abstract render(nodeClick: (nodeId: string) => void, selectedNodeId: string): JSX.Element
	abstract getInfosToBeDisplayed(): ITextWithDimensions[]

	computeDimensions(diagram: Diagram) {
		this.diagram = diagram
		this.computeSelfDimensions()
	}

	renderPerson(
		nodeClick: (nodeId: string) => void,
		shareGroupClicked: (parentId: string, childId: string) => void,
		selectedNodeId: string
	): JSX.Element {
		return (
			<g key={this.id} className="box" id={this.id}>
				{this.render(nodeClick, selectedNodeId)}
				{this.shareGroups.map((group) =>
					group.render(() => {
						shareGroupClicked(this.id, group.target)
					})
				)}
			</g>
		)
	}

	getContentDimensions(): IDimensions {
		const { textSpacing, sidePadding, topPadding } = this.diagram.getLayoutProperties()
		const content = this.getInfosToBeDisplayed()
		const contentWidth = maxBy(content, (content) => content.width).width
		const contentHeight = sumBy(content, (content) => content.height)
		const paddingHeight = (content.length - 1) * textSpacing

		return {
			width: contentWidth + 2 * sidePadding,
			height: contentHeight + paddingHeight + 2 * topPadding
		}
	}

	renderTextToBeDisplayed(): JSX.Element[] {
		const infos = this.getInfosToBeDisplayed()
		const { topPadding, textSpacing, shapesBorderWidth } = this.diagram.getLayoutProperties()
		let x = this.x + this.width / 2
		let y = this.y + topPadding + shapesBorderWidth
		let elements: JSX.Element[] = []
		for (let info of infos) {
			elements.push(
				<text
					key={generate()}
					className="svgText"
					x={x}
					y={y + getHangingFontOffset(info)}
					textAnchor="middle"
					fontSize={info.fontSize}
					fontFamily={info.fontFamily}
					fontWeight={info.fontWeight || FontWeight.Normal}
					fill={this.diagram.fontColor}
				>
					{info.text}
				</text>
			)
			y += info.height + textSpacing
		}
		return elements
	}
	setName(newName): Person {
		this.name = newName
		return this
	}

	setChild(childId: string): Person {
		this.shareGroups.push(new ShareGroup(this.id, { target: childId }))
		return this
	}

	setBgColor(newColor: string): Person {
		this.bgColor = newColor
		return this
	}

	deleteChild(childId: string): Person {
		this.shareGroups = this.shareGroups.filter((group) => group.target !== childId)
		return this
	}

	clone(): Person {
		return Object.assign(Object.create(Object.getPrototypeOf(this)), this)
	}

	setDiagram(diagram: Diagram) {
		this.diagram = diagram
	}
}

export default Person
