export enum FontWeight {
	Bold = 'bold',
	Normal = 'normal'
}

export interface ITextRequest {
	text: string
	fontSize: number
	fontFamily: string
	fontWeight?: FontWeight
}

export interface IDimensions {
	width: number
	height: number
}

export interface IPosition {
	x: number
	y: number
}

export interface IBox extends IDimensions, IPosition {}

export interface ITextWithDimensions extends ITextRequest, IDimensions, Partial<IPosition> {}

export function textDimensions(text: ITextRequest): ITextWithDimensions {
	const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')

	svg.innerHTML = `<text class="svgText" id="wassup" x="100" y="100" style="font-size:${text.fontSize};font-weight:${text.fontWeight ||
		FontWeight.Normal};font-family:${text.fontFamily};" fill="black">${text.text}</text>`

	const body = document.getElementsByTagName('body')
	body[0].appendChild(svg)
	const res = document.getElementById('wassup')

	const result = {
		width: res.getBoundingClientRect().width,
		height: res.getBoundingClientRect().height,
		...text
	}

	body[0].removeChild(svg)

	return result
}
