import wrap from 'word-wrap'
import { AdminType } from './DiagramApiDTO'
import { ITextWithDimensions } from './textDimensions'

const roleTranslations = {
	president: {
		en: 'President',
		fr: 'Président'
	},
	vicePresident: {
		en: 'Vice-president',
		fr: 'Vice-président'
	},
	tresorer: {
		en: 'Tresorer',
		fr: 'Trésorier'
	},
	secretary: {
		en: 'Secretary',
		fr: 'Sécrétaire'
	},
	administrator: {
		en: 'Administrator',
		fr: 'Administrateur'
	}
}

export const roleText = (role, lang: 'fr' | 'en') => {
	if (role === AdminType.PRESIDENT) {
		return translate(roleTranslations.president, lang)
	} else if (role === AdminType.VICE_PRESIDENT) {
		return translate(roleTranslations.vicePresident, lang)
	} else if (role === AdminType.SECRETARY) {
		return translate(roleTranslations.secretary, lang)
	} else if (role === AdminType.TRESORER) {
		return translate(roleTranslations.tresorer, lang)
	} else {
		return translate(roleTranslations.administrator, lang)
	}
}

var WHITE_SPACES = [
	' ',
	'\n',
	'\r',
	'\t',
	'\f',
	'\v',
	'\u00A0',
	'\u1680',
	'\u180E',
	'\u2000',
	'\u2001',
	'\u2002',
	'\u2003',
	'\u2004',
	'\u2005',
	'\u2006',
	'\u2007',
	'\u2008',
	'\u2009',
	'\u200A',
	'\u2028',
	'\u2029',
	'\u202F',
	'\u205F',
	'\u3000'
]

/**
* Remove chars from beginning of string.
*/
function ltrim(str, chars) {
	chars = chars || WHITE_SPACES

	var start = 0,
		len = str.length,
		charLen = chars.length,
		found = true,
		i,
		c

	while (found && start < len) {
		found = false
		i = -1
		c = str.charAt(start)

		while (++i < charLen) {
			if (c === chars[i]) {
				found = true
				start++
				break
			}
		}
	}

	return start >= len ? '' : str.substr(start, len)
}

/**
 * Remove chars from end of string.
 */
function rtrim(str, chars) {
	chars = chars || WHITE_SPACES

	var end = str.length - 1,
		charLen = chars.length,
		found = true,
		i,
		c

	while (found && end >= 0) {
		found = false
		i = -1
		c = str.charAt(end)

		while (++i < charLen) {
			if (c === chars[i]) {
				found = true
				end--
				break
			}
		}
	}

	return end >= 0 ? str.substring(0, end + 1) : ''
}

/**
  * Remove white-spaces from beginning and end of string.
  */
function trim(str) {
	return ltrim(rtrim(str, WHITE_SPACES), WHITE_SPACES)
}

export const wrapText = (txt: string, width: number, addchar: string = ','): string[] =>
	wrap(txt, { width: width, trim: true, newline: addchar }).split(',').map((i) => trim(i))

export const translate = (key: { en: string | JSX.Element; fr: string | JSX.Element }, lang: 'en' | 'fr'): any => {
	return key[lang]
}
const numberWithCommas = (x) => {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
const numberWithSpace = (x) => {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
export const formatLargeNumber = (number: number, lang: 'fr' | 'en') => {
	if (lang === 'en') {
		return numberWithCommas(number)
	} else {
		return numberWithSpace(number)
	}
}

export const textChunksInArray = (txt: string): string[] => txt.split('\n').map((t) => t.trim())

export const getHangingFontOffset = (text: ITextWithDimensions) => {
	return text.height * 0.7
}
