import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'react-bootstrap'

import Logger from './Logger'
import polyglot from '../Translator'

export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    contactUs: PropTypes.bool,
  }

  static defaultProps = {
    contactUs: true,
  }

  state = {
    hasError: false,
  }

  componentDidCatch(error, info) {
    Logger.error(error.toString(), info)
    this.setState({ hasError: true })
  }

  render() {
    return this.state.hasError ? (
      <Grid>
        <h1>{polyglot.t('somethingWentWrong')}</h1>
        <p>{polyglot.t('teamNotified')}</p>
        {this.props.contactUs && (
          <p>
            {polyglot.t('fillOutReportP1')}{' '}
            <a href="/contact-us?type=bug">{polyglot.t('fillOutReportP2')}</a>
            {'.'}
          </p>
        )}
      </Grid>
    ) : (
      this.props.children
    )
  }
}
