import { Icon } from 'antd'
import * as React from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import styled from 'styled-components'
import { graphTranslations } from '../../Diagrams/graphTranslations'
import polyglot from '../../Translator'
import {
  ImageLanding,
  MainTitleLanding,
  ParagraphLanding,
  TitleBoxLanding
} from './Sections/Landing/LandingStyleComponents'
import { landingTranslations } from './Sections/Landing/landingTranslations'

const IELandingGrid = styled.div`
  margin-top: 100px;
  /* background-color: transparent;
  display: grid;
  grid-gap: 10px;
  height: calc(100vh - 65px);
  padding-left: 10px;
  padding-right: 10px;
  grid-template-areas:
    'title'
    'img';
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
  background-image: linear-gradient(176deg, #ffffff 70%, #22313f calc(70% + 2px));
  @media (min-width: 450px) {
    grid-template-areas: 'title' 'img';
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
  } */
`
export interface IEFallbackProps {}

const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 65px;
  justify-content: space-around;
  align-items: center;
  width: 500px;
`

const IEFallback: React.SFC<IEFallbackProps> = () => {
  return (
    <div>
      <Navbar id="navbarFallback" style={{ height: 65 }} collapseOnSelect fluid fixedTop>
        <Navbar.Header>
          <img
            alt=""
            style={{ width: 400, height: 50, marginTop: 8 }}
            className="logo"
            src={require('../../img/logo-dark.svg')}
          />
          <Navbar.Toggle className="toggle" />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight>
            <WarningContainer>
              <Icon type="warning" style={{ color: 'red', fontSize: 20 }} />
              <div>
                <div>{polyglot.tr(graphTranslations.notSupportedP1)}</div>
                <div>{polyglot.tr(graphTranslations.notSupportedP2)}</div>
              </div>
            </WarningContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div>
        <IELandingGrid>
          <TitleBoxLanding>
            <MainTitleLanding>{polyglot.tr(landingTranslations.orgchartsQuick)}</MainTitleLanding>
            <ParagraphLanding>
              <span>{polyglot.tr(landingTranslations.buildOrgcharts)}</span>
              <br />
              <span>{polyglot.tr(landingTranslations.usingShareholderData)}</span>
            </ParagraphLanding>
          </TitleBoxLanding>
          <ImageLanding>
            <img
              alt=""
              style={{
                width: '70%',
                height: 'auto',
                boxShadow: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)'
              }}
              src="https://i.imgur.com/hi5yska.png"
            />
          </ImageLanding>
          <ImageLanding>
            <div style={{ marginTop: '50px', width: '50%', textAlign: 'center' }}>
              <h1>{polyglot.tr(graphTranslations.switchBrowser)}</h1>
            </div>
          </ImageLanding>
        </IELandingGrid>
      </div>
    </div>
  )
}

export default IEFallback
