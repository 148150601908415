import { AppRoutes, getRoute } from '../AppRoutes'
import ErrorMessages from '../DTO/ErrorMessages'
import history from './history'

export const handleAxiosError = response => {
  let correctlyHandled = false

  if (response && response.status === 401 && response.data) {
    if (response.data.error === ErrorMessages.EMAIL_NOT_VERIFIED) {
      history.push(getRoute(AppRoutes.VALIDATION_REQUIRED))
      correctlyHandled = true
    } else if (response.data.error === ErrorMessages.USER_MANY_SESSIONS) {
      history.push(getRoute(AppRoutes.DUPLICATE_SESSIONS))
      correctlyHandled = true
    }
  } else if (response && response.status === 403 && response.data) {
    history.push(getRoute(AppRoutes.UNALLOWED), response.data)
    correctlyHandled = true
  }

  return correctlyHandled
}
