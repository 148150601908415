import { Cookies } from 'react-cookie'
import config from '../config'

export const cookies = new Cookies()
export default cookies

export type AppCookie = 'language' | 'sessionId'

export const getCookie = (cookieName: AppCookie) => {
  return cookies.get(cookieName)
}

export const setCookie = (name: AppCookie, value: string) => {
  let domain
  if (config.ENV === 'dev') {
    domain = `${getDomain(window.location.hostname)}`
  } else {
    domain = `.${getDomain(window.location.hostname)}`
  }
  cookies.set(name, value, { maxAge: 100000, path: '/', domain: domain })
}

// Note : this algorithm doesn't work with ip adresses.
// ex : for 192.168.0.108, the returned domain is "0.108"
// therefore, the cookies won't be set correctly.
const getDomain = (url, subdomain = false) => {
  subdomain = subdomain || false

  url = url.replace(/(https?:\/\/)?(www.)?/i, '')

  if (!subdomain) {
    url = url.split('.')

    url = url.slice(url.length - 2).join('.')
  }

  if (url.indexOf('/') !== -1) {
    return url.split('/')[0]
  }

  return url
}
