import * as React from 'react'
import { generate } from 'shortid'
import { ITrust, PersonTypes } from '../DiagramApiDTO'
import { ITextRequest, ITextWithDimensions, textDimensions, FontWeight } from '../textDimensions'
import { wrapText, translate, getHangingFontOffset } from '../utils'
import Person from './Person'

const translations = {
	fiduciary: {
		en: 'Fiduciary',
		fr: 'Fiduciaire'
	},
	fiduciaries: {
		en: 'Fiduciaries',
		fr: 'Fiduciaires'
	}
}
export class Trust extends Person implements ITrust {
	type = PersonTypes.TRUST
	fiduciaires: string[] = []
	showFiduciaires = false

	static BaseHeightRatio = 1.5

	constructor(args: Partial<ITrust> = {}) {
		super(args)
		this.fiduciaires = args.fiduciaires || this.fiduciaires
		this.showFiduciaires = args.showFiduciaires || this.showFiduciaires
	}

	getInfosToBeDisplayed(): ITextWithDimensions[] {
		let result: ITextRequest[] = []
		const {
			fontSize,
			smallFont,

			fontFamily
		} = this.diagram.getLayoutProperties()

		wrapText(this.name, 20).forEach((text) => {
			result.push({ text: text, fontSize: fontSize, fontFamily: fontFamily, fontWeight: FontWeight.Bold })
		})

		if (this.fiduciaires.length) {
			// Singulier ou pluriel de fiduciaire
			if (this.fiduciaires.length === 1) {
				result.push({
					text: `${translate(translations.fiduciary, this.diagram.getLanguage())} :`,
					fontSize: smallFont,
					fontFamily: fontFamily
				})
			} else {
				result.push({
					text: `${translate(translations.fiduciaries, this.diagram.getLanguage())} :`,
					fontSize: smallFont,
					fontFamily: fontFamily
				})
			}

			this.fiduciaires.forEach((fiduciairy, index) => {
				if (index === this.fiduciaires.length - 2) {
					result.push({ text: `${fiduciairy} et`, fontSize: smallFont, fontFamily: fontFamily })
				} else {
					result.push({ text: fiduciairy, fontSize: smallFont, fontFamily: fontFamily })
				}
			})
		}

		return result.map((text) => textDimensions(text))
	}

	computeSelfDimensions() {
		const { fontSize } = this.diagram.getLayoutProperties()
		const contentDimensions = this.getContentDimensions()

		const r = Trust.BaseHeightRatio
		const x = contentDimensions.width
		const y = contentDimensions.height
		const k = fontSize

		const h = x / r + y + k
		const b = r * h

		this.width = b
		this.height = h
	}

	renderTextToBeDisplayed(): JSX.Element[] {
		const infos = this.getInfosToBeDisplayed()
		const dims = this.getContentDimensions()

		const { topPadding, textSpacing, shapesBorderWidth } = this.diagram.getLayoutProperties()

		const r = Trust.BaseHeightRatio
		const j = dims.width / r

		let x = this.x + this.width / 2

		let y = this.y + j + topPadding

		let elements: JSX.Element[] = []
		for (let info of infos) {
			elements.push(
				<text
					key={generate()}
					className="svgText"
					x={x}
					textAnchor="middle"
					y={y + getHangingFontOffset(info)}
					fontSize={info.fontSize}
					fontFamily={info.fontFamily}
					fill={this.diagram.fontColor}
					fontWeight={info.fontWeight || FontWeight.Normal}
				>
					{info.text}
				</text>
			)
			y += info.height + textSpacing
		}
		return elements
	}

	render(onClick: (nodeId: string) => void, selectedNodeId: string): JSX.Element {
		const elements = this.renderTextToBeDisplayed()
		const bgColor: string = selectedNodeId === this.id ? Person.SelectedColor : this.bgColor
		return (
			<g
				onClick={(e) => {
					e.stopPropagation()
					onClick(this.id)
				}}
			>
				<polygon
					points={`${this.x},${this.y + this.height} ${this.x + this.width / 2},${this.y} ${this.x + this.width},${this.y +
						this.height}`}
					style={{ fill: bgColor, stroke: 'black', strokeWidth: 1 }}
				/>
				{elements}
			</g>
		)
	}
}

export default Trust
