import * as React from 'react'
import { generate } from 'shortid'
import { IPhysicalPerson, PersonTypes } from '../DiagramApiDTO'
import { ITextWithDimensions, textDimensions, FontWeight } from '../textDimensions'
import { wrapText, getHangingFontOffset } from '../utils'
import Person from './Person'

export class PhysicalPerson extends Person implements IPhysicalPerson {
	type = PersonTypes.PERSON

	constructor(args: Partial<IPhysicalPerson> = {}) {
		super(args)
	}

	getInfosToBeDisplayed(): ITextWithDimensions[] {
		const { fontSize, fontFamily } = this.diagram.getLayoutProperties()
		const nameLines = wrapText(this.name, 12)
		const nameTexts = nameLines.map((nameLine) => ({
			text: nameLine,
			fontSize,
			fontFamily,
			fontWeight: FontWeight.Bold
		}))

		const result = nameTexts.map((text) => textDimensions(text))

		return result
	}

	computeSelfDimensions() {
		let maxDim = 0

		this.diagram.persons.forEach((p) => {
			if (p.type === PersonTypes.PERSON) {
				const pers = p as PhysicalPerson
				pers.setDiagram(this.diagram)
				const d = pers.getRealSelfDimensions()
				if (d.width > maxDim) {
					maxDim = d.width
				}
			}
		})

		this.width = maxDim
		this.height = maxDim
	}

	renderTextToBeDisplayed(): JSX.Element[] {
		let result: JSX.Element[] = []
		const d = this.getContentDimensions()
		const { textSpacing, topPadding } = this.diagram.getLayoutProperties()

		let y = this.y + this.height / 2 - d.height / 2 + topPadding

		const infos = this.getInfosToBeDisplayed()

		for (let info of infos) {
			result.push(
				<text
					key={generate()}
					className="svgText"
					x={this.x + this.width / 2}
					y={y + getHangingFontOffset(info)}
					textAnchor="middle"
					fontSize={info.fontSize}
					fontFamily={info.fontFamily}
					fontWeight={info.fontWeight || FontWeight.Normal}
					fill={this.diagram.fontColor}
				>
					{info.text}
				</text>
			)
			y += info.height + textSpacing
		}

		return result
	}

	private getRealSelfDimensions() {
		const d = this.getContentDimensions()

		const r = Math.sqrt((d.width / 2) ** 2 + (d.height / 2) ** 2)

		return {
			width: 2 * r,
			height: 2 * r
		}
	}

	render(onClick: (nodeId: string) => void, selectedNodeId: string): JSX.Element {
		const elements = this.renderTextToBeDisplayed()
		const bgColor: string = selectedNodeId === this.id ? Person.SelectedColor : this.bgColor
		return (
			<g
				onClick={(e) => {
					e.stopPropagation()
					onClick(this.id)
				}}
			>
				<circle
					key={this.id}
					cx={this.x + this.width / 2}
					cy={this.y + this.height / 2}
					r={this.width / 2}
					stroke="black"
					strokeWidth="1"
					fill={bgColor}
				/>
				{elements}
			</g>
		)
	}
}

export default PhysicalPerson
